import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { toast } from 'react-toastify';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import { QueryClient, QueryClientProvider } from "react-query";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { Worker } from '@react-pdf-viewer/core';
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({});
root.render(
  <React.StrictMode>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider
          clientId={
            '562946033629-aujj3op75kdbqsjrebnv899uh2suq8sk.apps.googleusercontent.com'
          }
        >
          <App />
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </Worker>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
