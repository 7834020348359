import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { GetTokenFunc } from '../utils/GetToken';

export const useGetWallet = () => {
  return useQuery({
    queryKey: ['userWallet'],
    queryFn: async () => {
      return await axios.get('api/v1/wallet/get-user-wallet', {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const useGetRechargeHistory = () => {
  return useQuery({
    queryKey: ['userRecharge'],
    queryFn: async () => {
      return await axios.get('api/v1/coupon/get-all-recharge-history', {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const useGetWalletHistroy = () => {
  return useQuery({
    queryKey: ['userWalletHistory'],
    queryFn: async () => {
      return await axios.get('api/v1/wallet/get-transactions-for-one-user', {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};
export const useApplyCoupon = () => {
  return useMutation({
    mutationKey: ['applycoupon'],
    mutationFn: async (data) => {
      return await axios.post('api/v1/coupon/apply-coupon', data, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};
export const useGiftCoupon = () => {
  return useMutation({
    mutationKey: ['giftcoupon'],
    mutationFn: async (data) => {
      return await axios.post('api/v1/coupon/apply-gift-coupon', data, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};
export const usePaymentGateway = () => {
  return useMutation({
    mutationKey: ['paymentgateway'],
    mutationFn: async (data) => {
      return await axios.post('api/v1/payment/accept_payment', data, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

// notification

export const useGetNotification = () => {
  return useQuery({
    queryKey: ['userNotification'],
    queryFn: async () => {
      return await axios.get('api/v1/notification/get_notifications', {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const useUpdateNotification = () => {
  return useMutation(async (data) => {
    return await axios.put('api/v1/notification/update_notifications', data, {
      headers: {
        Authorization: `Bearer ${GetTokenFunc()}`,
      },
    });
    // Assuming your API returns some data
  });
};
