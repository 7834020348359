import React, { useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  Button,
  Tooltip,
  Skeleton,
} from '@mui/material';
import * as Styles from '../../Common/Styles.js';
import workplaceimg from '../../Assets/mainPage/workplaceimg.svg';
import Overlay from '../../Assets/mainPage/Overlay.svg';
import newSearch from '../../Assets/mainPage/newSearch.svg';
import styled from 'styled-components';
const WorkPlace = () => {
  const [tab, setTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const tabWorkPlace = [
    {
      id: 1,
      icon: Overlay,
      title: 'Para Source',
      subText:
        'ParaSors is the citation and source engine of Para, enabling precise cross-referencing for ParaSearch and curating an E-Journal of cited cases within the Para ecosystem.',
    },
    {
      id: 2,
      icon: newSearch,
      title: 'Para Search',
      subText:
        'ParaSearch is an AI-powered legal research tool providing precise case law analysis from Indian judgments since 1950, streamlining legal workflows.',
    },
    {
      id: 3,
      icon: newSearch,
      title: 'Para Doc',
      subText:
        'ParaDoc is designed to analyze and manage legal documents with AI-powered precision, streamlining document review and insights generation.',
    },
  ];
  return (
    <Paper elevation={0}>
      <Box sx={setPageWorkPlaceStyle}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '80%',
            mx: 'auto',
          }}
        >
          <Typography sx={smallParaText}>Para</Typography>
          <Typography sx={largeParaText}>
            Designed to empower legal professionals, Parasors leverages advanced
            artificial intelligence to simplify the process of compiling,
            organizing, and citing legal information. With Para, searching for
            relevant legal materials becomes seamless
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {
              xl: 'row',
              lg: 'row',
              md: 'row',
              sm: 'column',
              xs: 'column',
            },
            mt: 2,
            width: '80%',
            mx: 'auto',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '25px',
              flex: 1,
            }}
          >
            <Typography sx={smallParaText}>Para</Typography>
            <Typography sx={Styles.workPlaceText}>
              Your new legal workspace.
            </Typography>
            {tabWorkPlace?.map((item, index) => (
              <div key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // width: '95%',
                    cursor: 'pointer',
                  }}
                  onClick={() => onTitleClick(index)}
                >
                  <Box sx={imgBoxStyle} onClick={() => onTitleClick(index)}>
                    <Box
                      component={'img'}
                      src={item.icon}
                      alt=""
                      sx={{ height: 'auto', maxWidth: 'auto' }}
                    />
                    <Typography
                      sx={{
                        ...Styles.newparasorsText,
                        color:
                          index === activeIndex
                            ? 'rgba(34, 34, 34, 1)'
                            : 'rgba(141, 141, 143, 1)',
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </Box>
                <AccordionContent
                  className={index === activeIndex ? 'open' : ''}
                >
                  <Typography sx={subTextPara}>{item?.subText}</Typography>
                </AccordionContent>
              </div>
            ))}
          </Box>
          <Box
            component={'img'}
            src={workplaceimg}
            alt=""
            sx={{
              height: 'auto',
              maxWidth: {
                xl: '550px',
                lg: '550px',
                md: '550px',
                sm: '300px',
                xs: '300px',
              },
              mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 },
              position: {
                xl: 'relative', // Ensure fixed positioning for large screens
                lg: 'relative',
                md: 'relative',
                sm: 'static',
                xs: 'static',
              },
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default WorkPlace;
const AccordionContent = styled(Box)(({ theme }) => ({
  maxHeight: '0',
  opacity: 0,
  visibility: 'hidden',
  transition: 'opacity 0.3s ease-out, visibility 0.3s ease-out',
  '&.open': {
    maxHeight: '500px', // Adjust this value based on your content
    opacity: 1,
    visibility: 'visible',
    transition: 'opacity 0.3s ease-in, visibility 0.3s ease-in',
  },
}));

const setPageWorkPlaceStyle = {
  width: '100%',
  height: {
    xl: '90vh',
    lg: '100vh',
    md: '100vh',
    sm: '100vh',
    xs: '100vh',
  },
  background: '#fff',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  mt: 0,
  p: { xl: 2, lg: 2, md: 2, sm: 0, xs: 0 },
  mb: { xl: 0, lg: 0, md: 0, sm: 2, xs: 5 },
};
const smallParaText = {
  fontFamily: 'Inter',
  fontSize: { xl: '12px', lg: '12px', md: '12px', sm: '10px', xs: '10px' },
  fontWeight: 400,
  color: 'rgba(21, 21, 21, 1)',
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
};

const largeParaText = {
  fontFamily: 'Inter',
  fontSize: { xl: '32px', lg: '32px', md: '32px', sm: '16px', xs: '16px' },
  fontWeight: 500,
  lineHeight: { xl: '38px', lg: '38px', md: '38px', sm: '22px', xs: '22px' },
  color: 'rgba(21, 21, 21, 1)',
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
};

const subTextPara = {
  fontFamily: 'Inter',
  mt: 0.5,
  fontSize: { xl: '13px', lg: '13px', md: '13px', sm: '12px', xs: '12px' },
  fontWeight: 400,
  color: 'rgba(141, 141, 143, 1)',
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
  width: { xl: '60%', lg: '60%', md: '60%', sm: '100%', xs: '100%' },
};

const imgBoxStyle = {
  display: 'flex',
  justifyContent: {
    xl: 'flex-start',
    lg: 'flex-start',
    md: 'flex-start',
    sm: 'center',
    xs: 'center',
  },
  alignItems: 'center',
  gap: '10px',
};
