import React, { useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  Button,
  Input,
  InputAdornment,
  IconButton,
} from '@mui/material';
import ReactGA from 'react-ga';
import sms from '../../Assets/LoginSignup/sms.png';
import lock from '../../Assets/LoginSignup/lock.png';
import googleimg from '../../Assets/LoginSignup/googleimg.png';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import mainLogo from '../../Assets/Header/mainLogo.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../HeaderUi/Header';
import menuHorz from '../../Assets/Header/menuHorz.png';
import ipimg from '../../Assets/LoginSignup/ipimg.svg';
import gglreg from '../../Assets/LoginSignup/gglreg.svg';
import phone from '../../Assets/Header/phone.png';
import { GoogleLogin } from '@react-oauth/google';
import pricetag from '../../Assets/Header/pricetag.png';
import setting from '../../Assets/Header/setting.png';
import help from '../../Assets/Header/help.png';
import Mask from '../../Assets/ProfileImg/Mask.png';
import * as Styles from '../../Common/Styles.js';
import paralogo from '../../Assets/Header/paralogo.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import { useSignIn, useGooleLoginAuth } from '../../Hooks/useSignUp';
import { useGoogleLogin } from '@react-oauth/google';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import cookie from 'js-cookie';
import {
  useCreateChatMessages,
  useGetUsersChatMessages,
} from '../../Hooks/chatHooks';
import { useGetUsersSinglePdfs } from '../../Hooks/FileUpload/fileHooks.js';
import { GetTokenFunc } from '../../utils/GetToken.js';
const SIgnIn = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  const [selectedItem, setSelectedItem] = useState('Home');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [chatId, setChatId] = useState(null);
  const { data: ChatMessages, refetch: ChatMessagesRefecth } =
    useGetUsersChatMessages(chatId?._id);
  // for input Box Glow on Click

  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };
  const handleInputBlur = () => {
    setIsInputFocused(false);
  };
  const [isInputFocusedInput, setIsInputFocusedInput] = useState(false);
  const handleInputFocusInput = () => {
    setIsInputFocusedInput(true);
  };
  const handleInputBlurInput = () => {
    setIsInputFocusedInput(false);
  };
  // hooks
  const {
    mutate: GoogleLoginMutate,
    isLoading: GoogleLoading,
    isError: GoogleError,
  } = useGooleLoginAuth();

  const login = useGoogleLogin({
    onSuccess: (res) => {
      GoogleLoginMutate(res.code, {
        onSuccess: (response) => {
          cookie.set('Bearer', response.data.token);
          navigate(`/mainchat/${id}`);
        },
        onError: (error) => {
          return;
          toast.error('Login Failed.', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        },
      });
    },
    onError: (error) => console.log('err', error),
    flow: 'auth-code',
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      z.object({
        email: z.string().email(),
        password: z.string().min(8),
        // .regex(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        //   'Password must be strong: include lowercase, uppercase, digit, and special characters'
        // ),
      })
    ),
  });

  const { mutate: SignInMutate, isLoading: SignInLoading, error } = useSignIn();
  const SignInSubmit = handleSubmit((data) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'SignIn Button',
      value: 1,
    });
    // SignInMutate(
    //   { ...data },
    //   {
    //     onSuccess: async (response) => {
    //       cookie.set('Bearer', response.data.token);
    //       const responseData = await axios.get(
    //         'api/v1/chat-session?userFiles=true',
    //         {
    //           headers: {
    //             Authorization: `Bearer ${GetTokenFunc()}`,
    //           },
    //         }
    //       );
    //       console.log('response from login', responseData);
    //       if (responseData?.data?.data?.length === 0) {
    //         navigate(`/mainchat/:id`);
    //       } else {
    //         navigate(`/mainchat/${responseData?.data?.data?.at(-1)?._id}`, {
    //           state: 'login',
    //         });
    //       }
    //       toast.success('Login Sucessfullyy !', {
    //         position: 'top-center',
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: 'light',
    //       });
    //     },
    //     onError: (error) => {
    //       // return;
    //       console.log(error, 'sddsdsd');
    //       toast.error(error.response?.data?.message, {
    //         position: 'top-center',
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: 'light',
    //       });
    //     },
    //   }
    // );

    SignInMutate(
      { ...data },
      {
        onSuccess: async (response) => {
          cookie.set('Bearer', response.data.token);
          const responseData = await axios.get(
            'api/v1/chat-session?userFiles=true',
            {
              headers: {
                Authorization: `Bearer ${GetTokenFunc()}`,
              },
            }
          );
          console.log('response from login', responseData);

          if (responseData?.data?.data?.length === 0) {
            navigate(`/mainchat/:id`);
          } else {
            navigate(`/mainchat/${responseData?.data?.data?.at(-1)?._id}`, {
              state: 'login',
            });
          }

          // Toast message styling for small screens
          const isSmallScreen = window.innerWidth < 600;

          toast.success('Login Successful !', {
            position: isSmallScreen ? 'top-center' : 'top-center',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              fontSize: isSmallScreen ? '14px' : '16px',
              padding: isSmallScreen ? '5px 8px' : '12px 20px',
              backgroundColor: '#4caf50', // Customize your background color
              color: '#fff',
            },
            theme: 'colored', // Light or dark theme as needed
          });
        },
        onError: (error) => {
          console.log(error);

          const isSmallScreen = window.innerWidth < 600;

          toast.error(error.response?.data?.message || 'An error occurred!', {
            position: isSmallScreen ? 'top-center' : 'top-center',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              fontSize: isSmallScreen ? '14px' : '16px',
              padding: isSmallScreen ? '10px 15px' : '12px 20px',
              backgroundColor: '#f44336', // Customize your background color
              color: '#fff',
            },
            theme: 'colored',
          });
        },
      }
    );
  });
  const headerStyle = {
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80px',
    transition: 'right 2s',
    color: 'white',
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    width: '80%',
    mx: 'auto',
    position: 'absolute',
    top: 0,
  };

  const navStyle = {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    position: 'absolute',
    top: '3rem',
    right: isMenuOpen ? '0' : '100%',
    backgroundColor: '#fff',
    width: '100%',
    transition: 'right 1s',
    height: '90vh',
    zIndex: 10000,
    borderRadius: '0px 20px 0px 0px',
  };
  // const [isInputFocused, setIsInputFocused] = useState(false);
  // const handleInputFocus = () => {
  //   setIsInputFocused(true);
  // };
  // const handleInputBlur = () => {
  //   setIsInputFocused(false);
  // };
  return (
    <Paper elevation={0} sx={Styles.PaperCommonDesReg}>
      <Box
        component={'img'}
        src={paralogo}
        alt=""
        onClick={() => {
          navigate('/');
        }}
        sx={{
          height: '40px',
          width: '40px',
          cursor: 'pointer',
          m: 1,
          transition: '0.3s',
          transform: 'scale(1)',
          display: {
            xl: 'flex',
            lg: 'flex',
            md: 'flex',
            sm: 'flex',
            xs: 'none',
          },
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.transform = 'scale(1.1)';
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.transform = 'scale(1)';
        }}
      />
      <Box
        sx={{
          display: {
            xl: 'none',
            lg: 'none',
            md: 'none',
            sm: 'none',
            xs: 'flex',
          },
        }}
      >
        <header style={headerStyle}>
          <Box sx={{}}>
            <Box
              onClick={() => {
                navigate('/');
              }}
              component={'img'}
              src={paralogo}
              alt=""
              sx={{
                height: 'auto',
                maxWidth: 'auto',
                cursor: 'pointer',
                m: 1,
                transition: '0.5s',
              }}
            />
          </Box>
        </header>
      </Box>
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={Styles.PaperGrid}
        >
          <Paper elevation={1} sx={Styles.paperBack}>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/signup');
              }}
            >
              <Typography
                sx={{
                  ...Styles.signUp,
                  fontSize: {
                    xl: '14px',
                    lg: '14px',
                    md: '12px',
                    sm: '8px',
                    xs: '8px',
                  },
                  transform: 'rotate(6deg)',
                  textAlign: 'right',
                  width: '85%',
                }}
              >
                Sign up
              </Typography>
            </Box>
            <Paper elevation={2} sx={Styles.paperDes}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  mt: { xl: 4, lg: 4, md: 3, sm: 2, xs: 0 },
                }}
              >
                <Typography sx={Styles.welText}>Sign In here!</Typography>{' '}
              </Box>
              <form onSubmit={SignInSubmit}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    width: {
                      xl: '80%',
                      lg: '80%',
                      md: '80%',
                      xs: '90%',
                      sm: '90%',
                    },
                    mx: 'auto',
                    gap: {
                      xl: '15px',
                      lg: '15px',
                      md: '15px',
                      sm: '10px',
                      xs: '5px',
                    },
                  }}
                >
                  <Typography sx={Styles.subText}>Email</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      mx: 'auto',
                    }}
                  >
                    <Input
                      id="standard-basic"
                      variant="outlined"
                      disableUnderline
                      placeholder="Email"
                      {...register('email')}
                      sx={{
                        ...Styles.InputFieldDes,
                        border: '1px solid rgba(209, 209, 209, 1)',
                        border: `2px solid ${
                          isInputFocusedInput
                            ? '#445FD2'
                            : 'rgba(209, 209, 209, 1)'
                        }`,
                      }}
                      onFocus={handleInputFocusInput}
                      onBlur={handleInputBlurInput}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            src={sms}
                            sx={Styles.iconStyle}
                          />
                        </InputAdornment>
                      }
                      inputProps={{
                        style: {
                          color: '#797979',
                          fontSize: '14px',
                          padding: '4px',
                          paddingLeft: '10px', // Adjust the left padding to make room for the icon
                        },
                      }}
                    />
                    <Typography sx={Styles.validationText}>
                      {errors.email?.message}
                    </Typography>
                  </Box>
                  <Typography sx={Styles.subText}>Password</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      mx: 'auto',
                    }}
                  >
                    <Input
                      id="standard-basic"
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      disableUnderline
                      placeholder="Password"
                      {...register('password')}
                      sx={{
                        width: 'auto',
                        height: {
                          xl: '42px',
                          lg: '42px',
                          md: '36px',
                          sm: '30px',
                          xs: '29px',
                        },
                        background: '#FFFFFF',
                        borderRadius: {
                          xl: '10px',
                          lg: '10px',
                          md: '9px',
                          sm: '8px',
                          xs: '8px',
                        },
                        fontSize: '12px',
                        color: '#445FD2',
                        px: 1,
                        border: '1px solid rgba(209, 209, 209, 1)',
                        border: `2px solid ${
                          isInputFocused ? '#445FD2' : 'rgba(209, 209, 209, 1)'
                        }`,
                        // fontWeight: isInputFocused ? 'bold' : 'normal',
                      }}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            src={lock}
                            sx={Styles.iconStyle}
                          />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPasswordClick}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{
                        style: {
                          color: '#797979',
                          fontSize: '14px',
                          padding: '4px',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                        },
                      }}
                    />
                    <Typography sx={Styles.validationText}>
                      {errors.password?.message}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      ...Styles.forgetText,
                      cursor: 'pointer',
                      width: 'auto',
                      ml: 'auto',
                    }}
                    onClick={() => {
                      navigate('/forgetpassword');
                    }}
                  >
                    Forgot Password?
                  </Typography>
                  <Box sx={{ ml: 'auto' }}></Box>
                  <Button type="submit" sx={Styles.signInBtn}>
                    {SignInLoading ? (
                      <ClipLoader color="inherit" size={20} />
                    ) : (
                      <Typography>Login</Typography>
                    )}
                  </Button>

                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    {/* <Box sx={Styles.regButton}>
                      <Box
                        component={'img'}
                        src={ipimg}
                        alt=""
                        sx={{ height: 'auto', width: '20px' }}
                      />
                      &nbsp; Static IP Address
                    </Box> */}
                    <Box sx={Styles.regButton} onClick={login}>
                      <Box
                        component={'img'}
                        src={gglreg}
                        alt=""
                        sx={{ height: 'auto', width: '20px' }}
                      />
                      &nbsp;Login with Google
                    </Box>
                  </Box>
                  {/* <Box sx={{ ...Styles.LogInBtn, mt: 2 }} onClick={login}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={googleimg}
                        sx={{
                          height: "auto",
                          width: {
                            xl: "25px",
                            lg: "25px",
                            md: "25px",
                            sm: "20px",
                            xs: "15px",
                          },
                        }}
                      />
                      Login with Google
                    </Box>
                  </Box> */}
                  {/* <GoogleLogin
                    onSuccess={async (credentialResponse) => {
                      GoogleLoginMutate(credentialResponse.credential, {
                        onSuccess: () => {},
                        onError: () => {},
                      });
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  /> */}
                </Box>
              </form>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SIgnIn;

const forgetText = {
  color: '#000',
  fontFamily: 'Lato',
  fontSize: { xl: '1rem', lg: '1rem', md: '1rem', sm: '0.6rem', xs: '0.6rem' },
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  cursor: 'pointer',
  '&:hover': {
    color: 'rgba(25, 168, 252, 1)',
  },
};

const headerTextStyle = {
  color: '#191919',
  fontFamily: 'Lato',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textAlign: 'left',
};
