import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  Grid,
  Tabs,
  Tab,
  Button,
  Input,
  Modal,
  Avatar,
  IconButton,
} from "@mui/material";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import MessageBox from "./MessageBox";
import pen from "../../Assets/ProfileImg/pen.png";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import paradoc from "../../Assets/Homeimg/paradoc.png";
import parasearch from "../../Assets/Homeimg/parasearch.png";
import closeicon from "../../Assets/ProfileImg/closeicon.png";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import mainLogo from "../../Assets/Header/mainLogo.svg";
import logout from "../../Assets/OuterImg/logout.png";
import add from "../../Assets/mainPage/uploadFileIcon.svg";
import paralogo from "../../Assets/Header/paralogo.svg";
import trash from "../../Assets/ProfileImg/trash.png";
import pluscircle from "../../Assets/mainPage/UploadPdfHereIcon.svg";
import logoutlite from "../../Assets/ProfileImg/logout.png";
import { Ring, Waveform } from "@uiball/loaders";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "./pdf.css";
import pageicon from "../../Assets/mainPage/pageicon.svg";
// import CloseIcon from '@mui/icons-material/Close';
// import IconButton from '@mui/material/IconButton';
import { useGetPdf, useGetMultiplePdf } from "../../Hooks/CommentHooks.js";
import { useFileUploadList } from "../../Hooks/FileUpload/useFileUploadList";
import trashTheme from "../../Assets/MessageBoxImg/trashTheme.png";
import axios from "axios";
import * as Styles from "../../Common/Styles.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import renderToolbar from "../CommonToast/renderToolbar.jsx";
import chaticon from "../../Assets/mainPage/chaticon.svg";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import cookie from "js-cookie";
import { useForm } from "react-hook-form";
import {
  useGetUsersSinglePdfs,
  useGetUsersPdfs,
  useDeleteChat,
  useDeleteAllChat,
  useDeleteChatPdf,
} from "../../Hooks/FileUpload/fileHooks.js";
import {
  useCreateChatMessages,
  useGetUsersChatMessages,
  useCreateNewChat,
  useGetNewChatList,
  useGetNewChatNameGenerator,
} from "../../Hooks/chatHooks";
import {
  useFileUpload,
  useSingleFileUpload,
  useUpdateFileUpload,
} from "../../Hooks/FileUpload/useFileUpload";
import {
  stringAvatar,
  stringToColor,
} from "../../Components/avatar/stringAvatar";
import {
  useGetUserProfile,
  useUpdateUserProfile,
  useUpdateIsCredited,
} from "../../Hooks/ProfileHooks";
import Header from "../HeaderUi/Header";
import { ExpandCircleDown } from "@mui/icons-material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import fillscreenview from "../../Assets/mainPage/fillscreenview.svg";
import shrinkscreenview from "../../Assets/mainPage/shrinkscreenview.svg";
import { useGetWallet, useGetWalletHistroy } from "../../Hooks/wallet.hooks.js";
const ChatPdfMessage = (props) => {
  const getFilePluginInstance = getFilePlugin();
  const navigate = useNavigate();
  const LocationData = useLocation();
  const [expanded, setExpanded] = useState(0);
  const [gridSize, setGridSize] = useState(true);
  const [selectedSingleFile, setSelectedSingleFile] = useState(null);
  const [chatId, setChatId] = useState();
  const [showPdf, setShowPdf] = useState();
  const [isPdfVisible, setIsPdfVisible] = useState(false);
  const [EnableGenerateChatName, setEnableGenerateChatName] = useState(false);
  const [isFirstGridMinimized, setIsFirstGridMinimized] = useState(true);
  const toggleFirstGrid = () => {
    setIsFirstGridMinimized(!isFirstGridMinimized);
  };
  const {
    data: ChatMessages,
    refetch: ChatMessagesRefecth,
    isInitialLoading,
    status,
  } = useGetUsersChatMessages(chatId?._id);
  const { id } = useParams();
  const {
    data: walletData,
    isLoading: walletLoading,
    error: walletError,
    refetch: walletDataRefetch,
  } = useGetWallet();
  const [openWalletModal, setOpenWalletModal] = React.useState(false);
  const [openCreditModal, setOpenCreditModal] = React.useState(false);
  const handleOpenWallet = () => setOpenWalletModal(true);
  const handleCloseWallet = () => setOpenWalletModal(false);
  const handleOpenCreditModel = () => setOpenCreditModal(true);
  const handleCloseCreditModel = () => setOpenCreditModal(false);

  const { mutate: updateCredit } = useUpdateIsCredited();

  // Get Users Profile Data
  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
    refetch: profileDataRefetch,
  } = useGetUserProfile();

  useEffect(() => {
    // Check if balance is 0 or undefined and open the modal
    if (walletData?.data?.at(0)?.balance === 0) {
      setOpenWalletModal(true);
    }
  }, [walletData]);

  useEffect(() => {
    if (profileData?.data?.data?.isCredited === false) {
      handleOpenCreditModel();
    }
  }, [profileData]);

  const handleCreditFlage = async () => {
    await updateCredit(
      {},
      {
        onSuccess: () => {
          handleCloseCreditModel();
        },
      }
    );
  };

  const closeModal = () => {
    setOpenWalletModal(false);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const [activeBox, setActiveBox] = useState(null);
  const [clearText, setclearText] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(true);
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleBoxClick = (item) => {
    setActiveBox(item);
  };
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingNewFile, setIsEditingNewFile] = useState(true);
  const [batchName, setBatchName] = useState("Collection Name");
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };
  const [todoList, setTodoList] = useState([]);
  const { mutate } = useDeleteChat();
  const handleDeleteItem = (id) => {
    mutate(
      { id },
      {
        onSuccess: () => {
          toast.success("Delete Successfully");
          newChatListRefetch();

          // Filter the updated chat list after deletion
          const updatedChatList = newChatList?.data?.data.filter(
            (item) => item._id !== id
          );

          // Filter the updated chat list file after deletion
          const updatedChatListFile = fileUploadListSingle?.data?.data.filter(
            (item) => item._id !== id
          );

          // Check if both updatedChatList and updatedChatListFile are empty
          if (
            (updatedChatList?.length === 0 || updatedChatList === null) &&
            (updatedChatListFile?.length === 0 || updatedChatListFile === null)
          ) {
            // Navigate to /mainchat/:id if both lists are empty
            navigate("/mainchat/:id");
          } else {
            // If updatedChatListFile is empty, handle fallback to updatedChatList
            if (
              updatedChatListFile?.length === 0 ||
              updatedChatListFile === null
            ) {
              if (updatedChatList.length > 0) {
                // Fallback to the last item in updatedChatList
                const lastChatId = updatedChatList.at(-1)?._id;
                navigate(`/mainchat/${lastChatId}`);
              }
            } else {
              // Continue with navigation based on updatedChatListFile

              const deletedIndexFile =
                fileUploadListSingle?.data?.data.findIndex(
                  (item) => item._id === id
                );

              // If the deleted file is not the last, navigate to the next file
              if (deletedIndexFile !== -1) {
                if (deletedIndexFile === updatedChatListFile.length) {
                  // If the deleted file is the last one, navigate to the previous one
                  const previousIdFile =
                    updatedChatListFile[deletedIndexFile - 1]?._id;
                  navigate(`/mainchat/${previousIdFile}`);
                } else {
                  // Navigate to the next file after deletion
                  const nextIdFile = updatedChatListFile[deletedIndexFile]?._id;
                  navigate(`/mainchat/${nextIdFile}`);
                }
              }
            }

            // If updatedChatList is not empty, handle navigation
            if (updatedChatList?.length > 0) {
              const deletedIndex = newChatList?.data?.data.findIndex(
                (item) => item._id === id
              );

              // If the deleted chat is not the last, navigate to the next chat
              if (deletedIndex !== -1) {
                if (deletedIndex === updatedChatList.length) {
                  // If the deleted item is the last one, navigate to the previous one
                  const previousId = updatedChatList[deletedIndex - 1]?._id;
                  navigate(`/mainchat/${previousId}`);
                } else {
                  // Navigate to the next chat after deletion
                  const nextId = updatedChatList[deletedIndex]?._id;
                  navigate(`/mainchat/${nextId}`);
                }
              }
            }
          }
        },
        onError: (error) => {
          toast.error("Failed to delete: " + error.message);
        },
      }
    );
  };
  const { mutate: deleteAllChat } = useDeleteAllChat();
  const handleDeleteAllItem = () => {
    deleteAllChat(
      {},
      {
        onSuccess: () => {
          toast.success("Delete Successfully");
          newChatListRefetch();
          const updatedChatList = newChatList?.data?.data.filter(
            (item) => item._id !== id
          );
          if (updatedChatList.length === 0) {
            navigate("/mainchat/:id");
          } else {
            const deletedIndex = newChatList?.data?.data.findIndex(
              (item) => item._id === id
            );
            if (deletedIndex !== -1) {
              if (deletedIndex === newChatList?.data?.data.length - 1) {
                const previousId =
                  updatedChatList[updatedChatList.length - 1]?._id;
                navigate(`/mainchat/${previousId}`);
              } else {
                const nextId = updatedChatList[deletedIndex]?._id;
                navigate(`/mainchat/${nextId}`);
              }
            }
          }
        },
        onError: (error) => {
          toast.error("Failed to delete: " + error.message);
        },
      }
    );
  };
  const [activeIndex, setActiveIndex] = useState(null);
  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const [singleFileOpen, setSingleFileOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [activeBoxCollection, setActiveBoxCollection] = useState(null);
  const handleInputChangeEdit = (e) => {
    setBatchName(e.target.value);
  };
  const handleSaveClick = () => {
    // You can implement the logic to save the edited batch name here
    setIsEditing(false);
  };
  const handleBoxClickCollection = (item) => {
    setActiveBoxCollection(item);
  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };

  const handleCloseSingle = (e) => {
    setSingleFileOpen(false);
  };
  //

  // Pdf Show time
  const [loading, setLoading] = useState(false);
  const handleViewPDFClickTime = () => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    setLoading(true);
    setGridSize(true);
    return () => clearTimeout(timer);
  };
  const [pageNumber, setPageNumber] = useState(1); // Initially displaying page 1

  // hover animation
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const {
    mutate: fileUpload,
    isLoading: fileUploadLoading,
    isError: fileUploadError,
  } = useFileUpload();

  const {
    mutate: updateFileList,
    isLoading: updateFileListLoading,
    isError: updatfileError,
  } = useUpdateFileUpload(chatId?._id);
  // single file upload
  const {
    mutate: fileSingleUpload,
    isLoading: fileSingleUploadLoading,
    isError: fileSingleUploadError,
  } = useSingleFileUpload();

  // upload multiple files list
  const {
    data: fileUploadList,
    isLoading: fileUploadListLoading,
    isError: fileUploadListError,
    refetch: refetchFileUploadList,
  } = useGetUsersPdfs();

  const {
    data: fileUploadListSingle,
    isLoading: fileUploadListSingleLoading,
    refetch: refetchFileUploadListSingle,
  } = useGetUsersSinglePdfs();
  console.log("datatatatata", fileUploadListSingle);

  // single
  useEffect(() => {
    if (value === 0) {
      if (fileUploadListSingle) {
        const findList = fileUploadListSingle?.data?.data?.find(
          (e) => e._id === id
        );
        setChatId(findList);
      }
    } else {
      if (fileUploadList) {
        const findList = fileUploadList?.data?.data?.find((e) => e._id === id);
        setChatId(findList);
      }
    }
  }, [fileUploadListSingle, fileUploadList]);

  const ChatMessagesClick = (id) => {
    navigate(`/mainchat/${id}`);
  };
  const handleDeleteFile = (key) => {
    setSelectedFile((prevSelectedFile) => {
      const newSelectedFile = { ...prevSelectedFile };
      delete newSelectedFile[key];
      return newSelectedFile;
    });
    setSelectedSingleFile(null);
  };

  const [selectPdf, setSelectPdf] = useState(true);
  const [selectMultiplePdf, setSelectMultiplePdf] = useState(true);
  const {
    data: singlePdf,
    isLoading: singlePdfLoading,
    refetch: refetchsinglePdf,
  } = useGetPdf(chatId);

  const [isTrashIconHovered, setTrshIconHovered] = useState(false);
  const handleTrashIconHover = (itemId) => {
    setTrshIconHovered(itemId);
  };
  const handleMouseLeaveHoevr = () => {
    setTrshIconHovered(null);
  };
  const {
    data: newChatList,
    isLoading: newChatListLoading,
    error: newChatListError,
    refetch: newChatListRefetch,
  } = useGetNewChatList();

  const {
    data: genrateChatName,
    isLoading: genrateChatNameLoading,
    refetch: genrateChatNameRefetch,
  } = useGetNewChatNameGenerator(id);

  const {
    mutate: newChatMutate,
    isLoading: newChatLoading,
    isError: newChatError,
  } = useCreateNewChat();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue: newChatSetValue,
    formState: { errors },
  } = useForm({});
  // const newChatNameSubmit = handleSubmit((data) => {
  //   console.log('newChatNameSubmit', data);
  //   newChatMutate(data, {
  //     onSuccess: (response) => {
  //       toast.success('Chat Created Successfully');
  //       handleCloseSingle();
  //       newChatListRefetch();
  //       reset();
  //     },
  //     onError: (error) => {
  //       console.log('error', error);
  //     },
  //   });
  // });

  const newChatNameSubmit = handleSubmit((data) => {
    const chatData = {
      ...data,
      name: data.name || "New Chat",
    };
    newChatMutate(chatData, {
      onSuccess: async (response) => {
        toast.success("Chat Created Successfully");
        handleCloseSingle();
        await newChatListRefetch();
        reset();
        setIsPdfVisible(false);
        navigate("/mainchat/" + response?.data?.data?._id);
      },
      onError: (error) => {},
    });
  });
  const clearInputBox = () => {
    if (clearText === true) {
      setclearText(false);
    } else {
      setclearText(true);
    }
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const defaultZoom = 1.2;
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });

  const allJudgements = [
    {
      id: 1,
      nameJudgement: "Supreme court",
      dateJudgements: "1950 to till date",
    },
    {
      id: 2,
      nameJudgement: "High Court",
      dateJudgements: "1950 to till date",
    },
    {
      id: 3,
      nameJudgement: "Subordinate Court",
      dateJudgements: "-",
    },
    {
      id: 1,
      nameJudgement: "Supreme court",
      dateJudgements: "1950 to till date",
    },
    {
      id: 2,
      nameJudgement: "High Court",
      dateJudgements: "1950 to till date",
    },
    {
      id: 3,
      nameJudgement: "Subordinate Court",
      dateJudgements: "-",
    },
  ];

  // file uploaded

  console.log("sdhsdjskd", newChatList);

  const { mutate: pdfChatList, refetch: newChatListPdfRefetch } =
    useDeleteChatPdf();
  const handleDeleteItemPdf = (index) => {
    pdfChatList(
      { id: index },
      {
        onSuccess: () => {
          toast.success("Delete Successfully");
          refetchFileUploadListSingle();

          const updatedChatList = fileUploadListSingle?.data?.data.filter(
            (item) => item._id !== id
          );
          if (updatedChatList.length === 0) {
            navigate(`/mainchat/${newChatList?.data?.data?.at(-1)?._id}`);
          } else {
            const deletedIndex = fileUploadListSingle?.data?.data.findIndex(
              (item) => item._id === id
            );

            if (deletedIndex !== -1) {
              if (
                deletedIndex ===
                fileUploadListSingle?.data?.data.length - 1
              ) {
                const previousId =
                  updatedChatList[updatedChatList.length - 1]?._id;
                navigate(`/mainchat/${previousId}`);
              } else {
                const nextId = updatedChatList[deletedIndex]?._id;
                navigate(`/mainchat/${nextId}`);
              }
            }
          }
        },
      }
    );
    const updatedList = todoList.filter((_, i) => i !== index);
    setTodoList(updatedList);
  };
  const handleDrop = (event) => {
    event.preventDefault();
    setSelectedSingleFile(event.dataTransfer.files[0]);
    uploadSingleFile(event);
  };
  const uploadSingleFile = (event) => {
    event.preventDefault();
    var formData = new FormData();
    // for (let i = 0; i < selectedSingleFile?.length; i++) {
    formData.append("file", selectedSingleFile);
    // }
    fileSingleUpload(formData, {
      onSuccess: (response) => {
        setSelectedSingleFile(null);
        setSingleFileOpen(false);
        refetchFileUploadListSingle();
      },
      onError: (error) => {},
    });
  };
  return (
    <>
      <Box
        sx={{
          display: {
            xl: "flex",
            lg: "flex",
            md: "flex",
            sm: "none",
            xs: "none",
          },
        }}
      >
        <Paper elevation={0} sx={Styles.PaperDesOther}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // p: 1,
              width: "95%",
              mx: "auto",
            }}
          >
            <Box
              component={"img"}
              src={paralogo}
              alt=""
              onClick={() => {
                navigate("/");
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = "scale(1.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
              sx={{
                height: "auto",
                width: "30px",
                cursor: "pointer",
                cursor: "pointer",
                transition: "0.3s",
                transform: "scale(1)",
              }}
            />
            <Box
              alt=""
              onClick={() => {
                navigate("/profile");
              }}
              sx={{
                height: "auto",
                width: "10px",
                cursor: "pointer",
                transition: "0.3s",
                transform: "scale(1)",
                position: "relative",
                mt: 0.5,
                mr: 2,
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = "scale(1.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
            >
              <Avatar {...stringAvatar(profileData?.data?.data?.fullName)} />
            </Box>
          </Box>
          <Grid
            container
            spacing={2.8}
            sx={{ display: "flex", flexWrap: "nowrap", mt: "-15px" }}
          >
            <Grid
              item
              xl={isFirstGridMinimized ? 0.5 : 2}
              lg={isFirstGridMinimized ? 0.5 : 2}
              md={isFirstGridMinimized ? 0.5 : 2}
              sx={{
                transition: "all 0.3s ease-in-out",
                gap: "2rem",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <IconButton
                onClick={toggleFirstGrid}
                sx={{
                  position: "absolute",
                  top: 20,
                  right: -4,
                  zIndex: 1000,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {isFirstGridMinimized ? (
                  // <CloseFullscreenIcon />
                  <Box
                    component={"img"}
                    src={shrinkscreenview}
                    sx={{
                      height: "auto",
                      width: "30px",
                      borderRadius: "5px",
                      transition: "all 0.3s ease-in-out",
                      boxShadow: "0px 10px 10px 0px rgba(147, 147, 147, 0.25)",
                      "&:hover": {
                        backgroundColor: "transparent",
                        boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.25)",
                      },
                    }}
                  />
                ) : (
                  // <FullscreenIcon />
                  <Box
                    component={"img"}
                    src={fillscreenview}
                    sx={{
                      height: "auto",
                      width: "30px",
                      borderRadius: "5px",
                      transition: "all 0.3s ease-in-out",
                      boxShadow: "0px 10px 10px 0px rgba(147, 147, 147, 0.25)",
                      "&:hover": {
                        backgroundColor: "transparent",
                        boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.15)",
                      },
                    }}
                  />
                )}
              </IconButton>
              <Box
                sx={{
                  opacity: isFirstGridMinimized ? 0 : 1,
                  visibility: isFirstGridMinimized ? "hidden" : "visible",
                  transition: "all 0.3s ease-in-out",
                  height: isFirstGridMinimized ? 0 : "auto",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={Styles.newChat}
                    // onClick={
                    //   value === 0
                    //     ? () => setSingleFileOpen(true)
                    //     : () => setOpen(true)
                    // }
                    onClick={() => newChatNameSubmit()}
                    onDragOver={handleDragOver}
                  >
                    {fileSingleUploadLoading ? (
                      <Ring
                        size={20}
                        lineWeight={2}
                        speed={2}
                        color="#605BFF"
                      />
                    ) : (
                      <Box sx={{ fontSize: "1.4rem" }}>+</Box>
                    )}
                    <Box
                      sx={{
                        fontSize: { xl: "14px", lg: "14px", md: "12px" },
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "14px", fontFamily: "Manrope" }}
                      >
                        New chat
                      </Typography>
                    </Box>
                    {/* single file */}
                    {/* <Modal
                    open={singleFileOpen}
                    onClose={() => {
                      setSingleFileOpen(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <form onSubmit={newChatNameSubmit}>
                      <Box sx={{ ...modalStyle, height: 'auto' }}>
                        <CloseIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCloseSingle();
                            setSelectedSingleFile(null);
                          }}
                          sx={{
                            cursor: 'pointer',
                          }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Input
                            id="standard-basic"
                            variant="outlined"
                            disableUnderline
                            {...register('name')}
                            placeholder="chat name"
                            sx={{
                              width: '40%',
                              height: '22px',
                              background: '#F4F5F6',
                              borderRadius: '5px',
                              fontSize: '12px',
                              color: '#445FD2',
                              p: 2,
                              border: 'none',
                              '&::placeholder': {
                                color: '#445FD2',
                              },
                            }}
                          />
                        </Box>
                        <Button
                          type="submit"
                          sx={{ ...signInBtn }}
                          // disabled={fileSingleUploadLoading ? true : false}
                        >
                          submit
                        </Button>
                      </Box>
                    </form>
                  </Modal> */}
                  </Box>
                  {/* <IconButton
                  onClick={toggleFirstGrid}
                  sx={{ background: 'red' }}
                >
                  {isFirstGridMinimized ? <ExpandMore /> : <Minimize />}
                </IconButton> */}
                  {/* single file modal chalu kar bhai */}
                  <Modal
                    open={singleFileOpen}
                    onClose={() => {
                      setSingleFileOpen(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={{ ...modalStyle, height: "auto" }}>
                      <Grid container>
                        <Grid
                          item
                          xl={7}
                          lg={7}
                          md={7}
                          sm={8}
                          xs={9}
                          sx={{
                            textAlign: "end",
                          }}
                        ></Grid>
                        <Grid
                          item
                          xl={5}
                          lg={5}
                          md={5}
                          sm={4}
                          xs={3}
                          sx={{
                            textAlign: "end",
                          }}
                        >
                          <CloseIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCloseSingle();
                              setSelectedSingleFile(null);
                            }}
                            sx={{
                              cursor: "pointer",
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Typography sx={subTextModal}>
                        Please upload{" "}
                        <Box
                          component={"span"}
                          sx={{ ...subTextModal, color: "primary.main" }}
                        >
                          Single
                        </Box>{" "}
                        pdf
                      </Typography>
                      <label
                        htmlFor="fileInput"
                        style={{
                          color: "#445FD2",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                      >
                        <Box
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                          sx={Styles.dropPdfStyle}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifycontent: "center",
                              alignItmes: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifycontent: "center",
                                alignItmes: "center",
                                gap: 1,
                                // mt: { xl: 5, lg: 4, md: 3, sm: 1, xs: 0 },
                                mt: 1,
                              }}
                            >
                              <Typography sx={Styles.MulishFontTextStyle}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {" "}
                                  <Box
                                    component={"img"}
                                    src={pluscircle}
                                    alt=""
                                    sx={{
                                      height: "auto",
                                      width: {
                                        xl: "24px",
                                        lg: "24px",
                                        md: "20px",
                                        sm: "16px",
                                        xs: "12px",
                                      },
                                    }}
                                  />
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                      setSelectedSingleFile(e.target.files[0]);
                                    }}
                                  />
                                </Box>{" "}
                                <Typography sx={Styles.uploadText2}>
                                  Drop your pdfs here
                                </Typography>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </label>
                      {selectedSingleFile !== null ? (
                        <>
                          <Typography sx={Styles.uploadedText}>
                            Uploaded
                          </Typography>
                          <Box sx={Styles.dropFileList}>
                            <Typography>
                              {selectedSingleFile
                                ? selectedSingleFile.name
                                : null}
                            </Typography>
                            <Box
                              onClick={handleDeleteFile}
                              component={"img"}
                              src={trash}
                              sx={{
                                height: "20px",
                                width: {
                                  xl: "20px",
                                  lg: "20px",
                                  md: "20px",
                                  sm: "16px",
                                  xs: "20px",
                                },
                                cursor: "pointer",
                              }}
                            />
                          </Box>
                        </>
                      ) : null}

                      <Button
                        onClick={uploadSingleFile}
                        sx={{ ...signInBtn, width: "60%" }}
                        // disabled={fileSingleUploadLoading ? true : false}
                      >
                        {fileSingleUploadLoading ? (
                          <>
                            <Waveform
                              size={40}
                              lineWeight={3.5}
                              speed={1}
                              color="#fff"
                            />
                          </>
                        ) : (
                          "upload"
                        )}
                      </Button>
                    </Box>
                  </Modal>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    typography: "body1",
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        width: "95%",
                        mx: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        sx={{
                          background: "primary.main",
                          justifyContent: "center", // Center the tabs
                          "& .MuiTabs-indicator": {
                            width: "100%", // Increase the width of the divider
                            mx: "auto",
                            textAlign: "center",
                            backgroundColor: "#828282",
                          },
                        }}
                      >
                        <Tab
                          sx={{
                            "&.Mui-selected": {
                              // color: 'primary.main',
                              color: "#68858C",
                            },
                            textTransform: "capitalize",
                            width: "100%",
                          }}
                          label={
                            <Box
                              component={"img"}
                              src={parasearch}
                              alt="Label Image"
                              sx={{ height: "auto", maxWidth: "100px" }} // You can adjust the size of the image
                            />
                          }
                        />
                        <Tab
                          onClick={() => {
                            setSelectPdf(true);
                            setChatId(fileUploadListSingle?.data?.data?.at(0));
                            navigate(
                              "/mainchat/" +
                                fileUploadListSingle?.data?.data?.at(-1)?._id
                            );
                          }}
                          sx={{
                            "&.Mui-selected": {
                              color: "primary.main",
                            },
                            textTransform: "capitalize",
                            width: "100%",
                          }}
                          label="Para Sors"
                        />
                      </Tabs>
                    </Box>

                    {/* <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      width: '95%',
                      mx: 'auto',
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      sx={{
                        background: 'primary.main',
                      }}
                    >
                      <Tab
                        onClick={() => {
                          setSelectPdf(true);
                          setChatId(fileUploadListSingle?.data?.data?.at(0));
                          console.log('chatIdiz', chatId);
                          navigate(
                            '/mainchat/' +
                              fileUploadListSingle?.data?.data?.at(-1)?._id
                          );
                        }}
                        sx={{
                          '&.Mui-selected': {
                            color: 'primary.main',
                          },
                          textTransform: 'capitalize',
                        }}
                        label="Para Sors"
                      />
                      <Tab
                        onClick={() => {
                          setSelectMultiplePdf(true);
                          setChatId(fileUploadList?.data?.data?.at(0));
                          refetchsinglePdf();
                          setExpanded(fileUploadList?.data?.data?.at(-1)._id);
                          navigate(
                            '/mainchat/' +
                              fileUploadList?.data?.data?.at(-1)?._id
                          );
                        }}
                        sx={{
                          '&.Mui-selected': {
                            color: 'primary.main',
                          },
                          background: 'transperent',
                          textTransform: 'capitalize',
                        }}
                        label="Para Doc"
                      />
                    </Tabs>
                  </Box> */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        mt: 2,
                        gap: 2,
                        borderBottom: "1px solid rgba(239, 239, 239, 1)",
                        py: 1,
                      }}
                    >
                      <Typography sx={{ ...Styles.que, fontSize: "12px" }}>
                        Your conversations
                      </Typography>
                      <Box onClick={() => handleDeleteAllItem()}>
                        <Typography
                          sx={{
                            ...Styles.que,
                            color: "primary.main",
                            fontSize: "9px",
                            cursor: "pointer",
                          }}
                        >
                          Clear All
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        minHeight: { xl: "65vh", lg: "63vh", md: "60vh" },
                      }}
                    >
                      <CustomTabPanel value={value} index={0}>
                        <Box
                          sx={{
                            height: "auto",
                            maxHeight: {
                              xl: "200px",
                              lg: "200px",
                              md: "200px",
                            },
                            overflowY: "auto",
                            "::-webkit-scrollbar": {
                              width: "3px",
                              display: "flex",
                            },
                            "::-webkit-scrollbar-thumb": {
                              background: "rgba(9, 26, 50, 0.7)",
                              borderRadius: "3px",
                            },
                            "::-webkit-scrollbar-thumb:vertical": {
                              maxHeight: "2px",
                              minHeight: "1px",
                            },
                            "::-webkit-scrollbar-thumb:horizontal": {
                              minWidth: "10px",
                            },
                            "::-webkit-scrollbar-track": {
                              background:
                                "rgba(133, 139, 160, 1)" /* Color of the track */,
                            },
                            width: "100%",
                            ml: 0.7,
                            mt: -2,
                          }}
                        >
                          {newChatList?.data?.data
                            ?.slice()
                            .reverse()
                            ?.map((item, index) => {
                              if (
                                activeBox === null ||
                                activeBox === undefined
                              ) {
                                if (index === 0) {
                                  handleBoxClick(item);
                                  // setChatId(item);
                                  ChatMessagesRefecth();
                                }
                              }
                              const chatNameWithoutSign = item?.name.replace(
                                /"/g,
                                ""
                              );
                              return (
                                <Box
                                  key={item._id}
                                  onClick={() => {
                                    handleBoxClick(item);
                                    setChatId(item);
                                    clearInputBox();
                                    ChatMessagesRefecth();
                                    ChatMessagesClick(item?._id);
                                    handleViewPDFClickTime();
                                    setPageNumber(1);
                                    setShowPdf("");
                                    setIsPdfVisible(false);
                                  }}
                                  sx={{
                                    ...SinglePdfDes,
                                    // transition: 'background 0.1s, color 0.1s',
                                    transition:
                                      "background 0.3s , color 0.3s , borderLeft 0.3s ease-in-out",
                                    "&:hover": {
                                      color: "#000",
                                      background: "#fff",
                                      borderLeft: "1px solid ",
                                      borderLeftColor: "rgba(9, 26, 50, 1)",
                                      opacity: 1,
                                    },
                                    opacity: expanded === index ? 1 : 0.8,
                                    backgroundColor:
                                      id === item._id ? "#fff" : "transperent",
                                    borderLeft:
                                      id === item._id
                                        ? "1px solid "
                                        : "transperent",
                                    // borderLeftColor: 'primary.main',
                                    borderLeftColor:
                                      "1px solid rgba(9, 26, 50, 1)",
                                    // borderImageSource:
                                    //   ' linear-gradient(90deg, rgba(115,211,242,1) 0%, rgba(186,145,237,1) 34%, rgba(255,96,192,1) 66%, rgba(255,140,95,1) 100%)',
                                    borderImageSlice: 1,
                                    // background:
                                    //   'linear-gradient(90deg, rgba(115, 211, 242, 1) 0%, rgba(186, 145, 237, 1) 33%, rgba(255, 96, 192, 1) 66%, rgba(255, 140, 95, 1) 100%)',
                                  }}
                                >
                                  <Box
                                    component={"img"}
                                    src={chaticon}
                                    alt=""
                                    sx={{
                                      height: "20px",
                                      maxWidth: "20px",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      ...Styles.que,
                                      // textOverflow: 'ellipsis',
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: 1,
                                      WebkitBoxOrient: "vertical",
                                    }}
                                  >
                                    {/* {item?.fileData
                                    ?.at(0)
                                    ?.fileName.slice(0, 15) +
                                    (item?.fileData?.at(0)?.fileName.length > 15
                                      ? '...'
                                      : '')} */}
                                    {/* {item?.name} */}
                                    &nbsp; {chatNameWithoutSign}
                                  </Typography>
                                  <Box
                                    onClick={() => handleDeleteItem(item?._id)}
                                    component={"img"}
                                    src={
                                      isTrashIconHovered === item._id
                                        ? trashTheme
                                        : trash
                                    }
                                    onMouseEnter={() =>
                                      handleTrashIconHover(item?._id)
                                    }
                                    onMouseLeave={handleMouseLeaveHoevr}
                                    sx={{
                                      height: "20px",
                                      maxWidth: "20px",
                                      ml: 0.5,
                                    }}
                                  />
                                </Box>
                              );
                            })}
                        </Box>
                      </CustomTabPanel>
                      {/* file uploaded list */}
                      <CustomTabPanel value={value} index={0}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "85%",
                            mx: "auto",
                            mt: -3,
                          }}
                        >
                          <Box
                            component={"img"}
                            src={paradoc}
                            sx={{ height: "auto", maxWidth: "90px" }}
                          />
                          <FileUploadIcon
                            sx={{
                              cursor: "pointer",
                              "&:hover": { color: "primary.main" },
                            }}
                            onClick={
                              value === 0
                                ? () => setSingleFileOpen(true)
                                : () => setOpen(true)
                            }
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                          />
                        </Box>
                        <Box
                          sx={{
                            height: "auto",
                            maxHeight: {
                              xl: "100px",
                              lg: "100px",
                              md: "100px",
                            },
                            overflowY: "auto",
                            width: "100%",
                            ml: 0.7,
                            mt: 1,
                          }}
                        >
                          {fileUploadListSingle?.data?.data
                            ?.slice()
                            .reverse()
                            ?.map((item, index) => {
                              if (
                                activeBox === null ||
                                activeBox === undefined
                              ) {
                                if (index === 0) {
                                  handleBoxClick(item);
                                  // setChatId(item);
                                  ChatMessagesRefecth();
                                }
                              }
                              return (
                                <Box
                                  key={item._id}
                                  onClick={() => {
                                    handleBoxClick(item);
                                    setChatId(item);
                                    clearInputBox();
                                    ChatMessagesRefecth();
                                    ChatMessagesClick(item?._id);
                                    handleViewPDFClickTime();
                                    setPageNumber(1);
                                    setIsPdfVisible(false);
                                  }}
                                  sx={{
                                    ...SinglePdfDes,
                                    // transition: 'background 0.1s, color 0.1s',
                                    transition:
                                      "background 0.3s , color 0.3s , borderLeft 0.3s ease-in-out",
                                    "&:hover": {
                                      color: "#000",
                                      background: "#fff",
                                      borderLeft: "1px solid",
                                      borderLeftColor: "primary.main",
                                      opacity: 1,
                                    },
                                    opacity: expanded === index ? 1 : 0.8,
                                    backgroundColor:
                                      id === item._id ? "#fff" : "transperent",
                                    borderLeft:
                                      id === item._id
                                        ? "1px solid"
                                        : "transperent",
                                    borderLeftColor: "primary.main",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...Styles.que,
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item?.name.slice(0, 15) +
                                      (item?.name.length > 15 ? "..." : "")}
                                  </Typography>
                                  <Box
                                    onClick={() => handleDeleteItem(item?._id)}
                                    component={"img"}
                                    src={
                                      isTrashIconHovered === item._id
                                        ? trashTheme
                                        : trash
                                    }
                                    onMouseEnter={() =>
                                      handleTrashIconHover(item?._id)
                                    }
                                    onMouseLeave={handleMouseLeaveHoevr}
                                    sx={{
                                      height: "20px",
                                      maxWidth: "20px",
                                    }}
                                  />
                                </Box>
                              );
                            })}
                        </Box>
                      </CustomTabPanel>

                      {/* <CustomTabPanel value={value} index={1}>
                      <Box
                        sx={{
                          height: 'auto',
                          maxHeight: { xl: '200px', lg: '200px', md: '150px' },
                          overflowY: 'auto',
                        }}
                      >
                        {fileUploadList?.data?.data
                          ?.slice()
                          .reverse()
                          .map((item, index) => {
                            if (
                              activeBoxCollection === null ||
                              activeBoxCollection === undefined
                            ) {
                              if (index === 0) {
                                handleBoxClickCollection(item);
                                // setChatId(item);
                                ChatMessagesRefecth();
                              }
                            }
                            return (
                              <Accordion
                                key={item._id}
                                expanded={expanded === item._id}
                                onChange={handleAccordionChange(item._id)}
                                onClick={() => {
                                  // handleBoxClick(item);
                                  setChatId(item);
                                  clearInputBox();
                                  ChatMessagesRefecth();
                                  ChatMessagesClick(item?._id);
                                  setPageNumber(1);
                                  handleBoxClickCollection(item);
                                }}
                                sx={{
                                  border: 'none',
                                  boxShadow: 'none',
                                  backgroundColor: 'transparent',
                                  mt: 1,
                                }}
                              >
                                <AccordionSummary
                                  sx={{
                                    ...AccordionSummaryCollection,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    backgroundColor:
                                      expanded === item._id
                                        ? '#fff '
                                        : 'transparent ',
                                    borderLeft:
                                      expanded === item._id
                                        ? '1px solid '
                                        : ' transparent ',
                                    borderLeftColor: 'primary.main',
                                    borderRadius:
                                      activeBoxCollection === item
                                        ? '0px 10px 0px 0px '
                                        : '0px 10px 10px 0px',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: '95%',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...Styles.que,
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      {item?.batchName
                                        ? item.batchName.slice(0, 10) +
                                          (item.batchName.length > 10
                                            ? '...'
                                            : '')
                                        : 'batchName'}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '10px',
                                      }}
                                    >
                                      <Box
                                        onClick={() =>
                                          // handleDeleteItem(item?._id)
                                          handleDeleteItemMultiple(item?._id)
                                        }
                                        component={'img'}
                                        src={
                                          isTrashIconHovered === item._id
                                            ? trashTheme
                                            : trash
                                        }
                                        onMouseEnter={() =>
                                          handleTrashIconHover(item?._id)
                                        }
                                        onMouseLeave={handleMouseLeaveHoevr}
                                        sx={{
                                          height: '20px',
                                          maxWidth: '20px',
                                          // mr: 2,
                                        }}
                                      />
                                      <FileUploadIcon
                                        onClick={() => {
                                          // if (value === 0) {
                                          //   setIsEditingNewFile(true);
                                          // } else {
                                          //   setIsEditingNewFile(false);
                                          // }
                                          handleModalSetMultipleEdit();
                                          setBatchName(item?.batchName);
                                          console.log(
                                            'handleModalSetMultipleEdit',
                                            handleModalSetMultipleEdit
                                          );
                                        }}
                                        sx={{
                                          '&:hover': { color: 'primary.main' },
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </AccordionSummary>

                                <AccordionDetails
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: '82%',
                                    borderRadius: '0px 0px 10px 0px',
                                    backgroundColor:
                                      activeBoxCollection === item
                                        ? '#fff '
                                        : 'transparent ',
                                    borderLeft:
                                      activeBoxCollection === item
                                        ? '1px solid '
                                        : ' transparent ',
                                    borderLeftColor: 'primary.main',
                                  }}
                                >
                                  {item?.fileData?.map((chiItem, idx) => {
                                    console.log('chiItem', chiItem);
                                    return (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          width: '80%',
                                          mx: 'auto',
                                          mt: 0.5,
                                          gap: '15px',
                                        }}
                                      >
                                        <Typography sx={Styles.subtrashText}>
                                          {chiItem?.fileName?.slice(0, 15) +
                                            (chiItem?.fileName?.length > 15
                                              ? '...'
                                              : '')}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </AccordionDetails>
                              </Accordion>
                            );
                          })}
                      </Box>
                    </CustomTabPanel> */}

                      {[1].map((item, index) => (
                        <>
                          <div key={index}>
                            <Box
                              sx={{
                                borderRight: "2px solid rgba(232, 232, 232, 2)",
                                borderLeft: "2px solid rgba(232, 232, 232, 2)",
                                borderTop: "2px solid rgba(232, 232, 232, 2)",
                                borderBottom: "none",
                                p: 0.8,
                                borderRadius:
                                  activeIndex === index
                                    ? "10px 10px 0px 0px"
                                    : "10px 10px 10px 10px",
                                background: "#fff",
                                ml: 1,
                                width: "75%",
                                mx: "auto",
                                cursor: "pointer",
                              }}
                              onClick={() => onTitleClick(index)}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography sx={Styles.footersubText}>
                                  My Library
                                </Typography>
                                <Box
                                  className="accordion-title"
                                  mt={0.3}
                                  onClick={() => onTitleClick(index)}
                                >
                                  {index === activeIndex ? (
                                    <KeyboardArrowUpIcon
                                      sx={{
                                        color: "rgba(84, 84, 84, 1)",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <ExpandMoreIcon
                                      sx={{
                                        color: "rgba(84, 84, 84, 1)",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              className="accordion-content"
                              style={{
                                display:
                                  index === activeIndex ? "block" : "none",
                                width: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  borderRight:
                                    "2px solid rgba(232, 232, 232, 2)",
                                  borderLeft:
                                    "2px solid rgba(232, 232, 232, 2)",
                                  borderBottom:
                                    "2px solid rgba(232, 232, 232, 2)",
                                  borderTop: "none",
                                  p: 1,
                                  borderRadius: "0px 0px 10px 10px",
                                  background: "#fff",
                                  ml: 1,
                                  width: "74%",
                                  mx: "auto",
                                }}
                              >
                                <Box
                                  sx={{
                                    borderTop:
                                      "1.5px solid rgba(232, 232, 232, 2)",
                                    width: "100%",
                                    mx: "auto",
                                    mt: -1.5,
                                  }}
                                />
                                <Box sx={DraftScroller}>
                                  {allJudgements?.map((item, index) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                        mt: 0.5,
                                      }}
                                    >
                                      <Box
                                        component={"img"}
                                        src={pageicon}
                                        alt="img"
                                        sx={{ height: "auto", width: "auto" }}
                                      />
                                      <Box>
                                        <Typography
                                          sx={{
                                            ...Styles.tableBodySubText,
                                            textAlign: "left",
                                          }}
                                        >
                                          {item?.nameJudgement}
                                        </Typography>
                                        <Typography
                                          sx={Styles.subheadingtabletext}
                                        >
                                          {item?.dateJudgements}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        </>
                      ))}
                      <Box
                        sx={{ ...Styles.LogOut, ml: 3 }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => {
                          cookie.remove("Bearer");
                          window.location.href = "/";
                        }}
                      >
                        <Box>Log Out</Box>
                        <Box
                          component={"img"}
                          src={isHovered ? logoutlite : logout}
                          alt="img"
                          sx={{
                            height: "auto",
                            width: {
                              xl: "20px",
                              lg: "20px",
                              md: "20px",
                              sm: "15px",
                              xs: "15px",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xl={isFirstGridMinimized ? 5 : 6}
              lg={isFirstGridMinimized ? 5 : 6}
              md={isFirstGridMinimized ? 5 : 6}
              sx={{ gap: "2rem", transition: "all 0.3s ease-in-out" }}
            >
              {openWalletModal && (
                <Modal
                  open={handleOpenWallet}
                  onClose={handleCloseWallet}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <IconButton
                      aria-label="close"
                      onClick={handleCloseWallet}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "#555",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      component="h2"
                      sx={{
                        fontWeight: "bold",
                        color: "#333",
                        textAlign: "center",
                      }}
                    >
                      Insufficient Balance
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, color: "#555", textAlign: "center" }}
                    >
                      Your balance is 0. Please recharge to continue using our
                      services.
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 3 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ mr: 2 }}
                        // onClick={handleCloseWallet}
                        onClick={() =>
                          navigate("/profile", { state: { activeTab: 2 } })
                        }
                      >
                        Recharge Now
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              )}

              {openCreditModal && (
                <Modal
                  open={handleOpenCreditModel}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    {/* <IconButton
                      aria-label="close"
                      onClick={handleCloseCreditModel}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "#555",
                      }}
                    >
                      <CloseIcon />
                    </IconButton> */}
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      component="h2"
                      sx={{
                        fontWeight: "bold",
                        color: "#333",
                        textAlign: "center",
                      }}
                    >
                      Credited Information
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, color: "#555", textAlign: "center" }}
                    >
                      You have get 20 free credits
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 3 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ mr: 2 }}
                        // onClick={handleCloseWallet}
                        onClick={() =>
                          // navigate("/profile", { state: { activeTab: 2 } })
                          handleCreditFlage()
                        }
                      >
                        Ok
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              )}
              <MessageBox
                chatData={chatId}
                value={value}
                selectPdf={selectPdf}
                batchName={batchName}
                selectMultiplePdf={selectMultiplePdf}
                isOpenPdf={gridSize}
                setPageNumber={setPageNumber}
                routeId={id}
                showPdf={showPdf}
                setShowPdf={setShowPdf}
                // clearInput={clearInput}
                clearInput={clearText}
                genrateChatName={genrateChatName}
                genrateChatNameRefetch={genrateChatNameRefetch}
                setEnableGenerateChatName={setEnableGenerateChatName}
                newChatListRefetch={newChatListRefetch}
                setIsPdfVisible={setIsPdfVisible}
              />
            </Grid>
            <Grid
              item
              xl={isFirstGridMinimized ? 6.5 : 4}
              lg={isFirstGridMinimized ? 6.5 : 4}
              md={isFirstGridMinimized ? 6.5 : 4}
              sx={{
                transition: "all 0.3s ease-in-out",
                cursor: "pointer",
                display: "flex",
                gap: "2rem",
                maxWidth: "100% !important",
              }}
            >
              <Box
                sx={{
                  flex: gridSize ? "1 1 7%" : " 1 1 40%",
                }}
              >
                <Box sx={pdfViewStyle}>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                      flexDirection: "column",
                      width: "98%",
                      mx: "auto",
                    }}
                  >
                    {loading ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            minHeight: "87vh",
                          }}
                        >
                          <Ring
                            size={60}
                            lineWeight={2}
                            speed={2}
                            color="#A1A1A1"
                          />
                        </Box>
                      </>
                    ) : (
                      <Box>
                        <Box sx={innerPdfStyle}>
                          <Box
                            sx={{
                              overflowX: "hidden",
                              scale: "1",
                              // marginTop:"-80px",
                              width: "100%",
                              height: "80%",
                              maxHeight: { xl: "620px", lg: "550px" },
                              overflowY: "hidden",
                              mt: 2,
                            }}
                          >
                            <div
                              style={{
                                height: "calc(100vh - 60px)",
                                overflowY: "auto",
                              }}
                            >
                              {!isPdfVisible ? (
                                <Box
                                  sx={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  Click on a source link to preview the PDF
                                </Box>
                              ) : (
                                showPdf && (
                                  <Viewer
                                    fileUrl={showPdf}
                                    initialPage={pageNumber}
                                    defaultScale={defaultZoom}
                                    plugins={[
                                      defaultLayoutPluginInstance,
                                      getFilePluginInstance,
                                    ]}
                                  />
                                )
                              )}
                            </div>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};
export default ChatPdfMessage;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        background: "primary",
      }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const pdfViewStyle = {
  width: "98%",
  height: "98%",
  minHeight: "90vh",
  maxHeight: "90vh",
  background: "#FFF",
  boxShadow: "0px 10px 20px 0px rgba(147, 147, 147, 0.25)",
  borderRadius: "10px",
};
const innerPdfStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "90%",
  mx: "auto",
  mt: 1,
  p: 2,
  gap: "20px",
};
const subTextModal = {
  color: "rgba(71, 84, 103, 0.81)",
  textAlign: "center",
  fontFamily: "Lato",
  fontSize: { xl: "14px", lg: "14px", md: "12px", sm: "10px", xs: "10px" },
  fontStyle: "normal",
  fontWeight: 400,
  mt: 0.5,
};
const SinglePdfDes = {
  display: "flex",
  justifyContent: "space-between",
  width: "85%",
  mt: 1,
  borderRadius: "0px 10px 10px 0px",
  p: 1.5,
  cursor: "pointer",
};
const signInBtn = {
  borderRadius: "10px",
  backgroundColor: "primary.main",
  color: "#FFF",
  fontFamily: "Lato",
  fontSize: { xl: "1rem", lg: "1rem", md: "1rem", sm: "0.6rem", xs: "0.6rem" },
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textTransform: "none",
  p: { xl: 2, lg: 2, md: 2, sm: 1.5, xs: 1.5 },
  mt: 4,
  width: "40%",
  mx: "auto",
  textAlign: "center",
  display: "flex",
  justifcontent: "center",
  "&:hover": {
    backgroundColor: "primary.main",
  },
};
const modalStyle = {
  position: "absolute",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #19A8FC",
  borderColor: "primary.main",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "50%",
  width: "60%",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: 400, lg: 400, md: 400, sm: 300, xs: 300 },
  bgcolor: "background.paper",
  background: "linear-gradient(135deg, #ece9e6 0%, #ffffff 100%)",
  borderRadius: "12px",
  boxShadow: 24,
  p: { xl: 4, lg: 4, md: 4, sm: 1.5, xs: 1.5 },
  outline: "none", // Remove the default border
};

const DraftScroller = {
  mt: 0.5,
  height: "70px",
  overflowY: "scroll",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
  },
  "::-webkit-scrollbar-thumb": {
    background: "rgba(9, 26, 50, 0.7)",
    borderRadius: "3px",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxHeight: "3px",
    minHeight: "1px",
  },
  "::-webkit-scrollbar-track": {
    background: "rgba(133, 139, 160, 1)" /* Color of the track */,
  },
};
