import {
  Box,
  Button,
  Input,
  Modal,
  Paper,
  Popover,
  Tooltip,
  Typography,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetWallet, useGetWalletHistroy } from "../../Hooks/wallet.hooks.js";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import "react-toastify/dist/ReactToastify.css";
import circleloader from "../../Assets/Header/newLoaderBlue.mp4";
import sendicon from "../../Assets/OuterImg/sendicon.svg";
import share from "../../Assets/OuterImg/shareActive.svg";
import shareInactive from "../../Assets/OuterImg/shareInactive.svg";
import ChatIcon from "../../Assets/OuterImg/commentsIconActive.svg";
import ChatIconInActive from "../../Assets/OuterImg/CommentIconInActive.svg";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
import BGImage from "../../Assets/mainPage/chatMessageBg.svg";
import MicIcon from "@mui/icons-material/Mic";
import DownloadIcon from "@mui/icons-material/Download";
import HelpIcon from "@mui/icons-material/Help";
import * as Styles from "../../Common/Styles.js";
import { useGetUserProfile } from "../../Hooks/ProfileHooks";
import batchImg from "../../Assets/MessageBoxImg/batchImg.svg";
import batchGreyImg from "../../Assets/MessageBoxImg/batchGreyImg.svg";
import closeModalImg from "../../Assets/MessageBoxImg/closeModalImg.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { PDFDocument, rgb } from "pdf-lib";
import sourcesImg from "../../Assets/MessageBoxImg/sourcesImg.svg";
import { Ring, Waveform, DotPulse } from "@uiball/loaders";
import {
  useCreateChatMessages,
  useGetUsersChatMessages,
  useShareChatMessages,
  useGetFollowUpMessages,
  useGetNewChatList,
  useCreateNewChat,
} from "../../Hooks/chatHooks";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Markdown from "react-markdown";
import AnimatedText from "./AnimatedText";
import {
  useGetUsersSinglePdfs,
  useGetUsersPdfs,
  useDeleteChat,
} from "../../Hooks/FileUpload/fileHooks.js";
import { useGetBatchList } from "../../Hooks/ProfileHooks";
import { GetTokenFunc } from "../../utils/GetToken.js";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
const MessageBox = ({
  chatData,
  selectPdf,
  selectMultiplePdf,
  isOpenPdf,
  batchName,
  setPageNumber,
  routeId,
  clearInput,
  value,
  showPdf,
  setShowPdf,
  setIsPdfVisible,
  genrateChatName,
  genrateChatNameRefetch,
  setEnableGenerateChatName,
  newChatListRefetch,
}) => {
  // Pdf Show time
  const [gridSize, setGridSize] = useState(false);
  const [helpModel, setHelpModal] = useState(false);
  const [isChatIconHovered, setChatIconHovered] = useState(false);
  const [isShareIconHovered, setShareIconHovered] = useState(false);
  const LocationData = useLocation();
  const [animateLastMessage, setAnimateLastMessage] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const handleOpenComments = () => setOpenComments(true);
  const handleCloseComments = () => setOpenComments(false);
  const handleChatIconHover = () => {
    setChatIconHovered(true);
    setShareIconHovered(false);
  };
  const handleShareIconHover = () => {
    setChatIconHovered(false);
    setShareIconHovered(true);
  };
  const handleMouseLeaveHoevr = () => {
    setChatIconHovered(false);
    setShareIconHovered(false);
  };

  const navigate = useNavigate();
  // const id = chatData?._id;
  // const handleSourceLinkClick = (fileData) => {
  //   setIsPdfVisible(true);
  //   setShowPdf(fileData);
  // };
  const { id } = useParams();
  const {
    data: walletData,
    isLoading: walletLoading,
    error: walletError,
    refetch: walletDataRefetch,
  } = useGetWallet();
  //   Message box
  const [chatId, setChatId] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const { mutate: createChatMessage, isLoading: chatMessageLoading } =
    useCreateChatMessages(id);
  const [lastMessageId, setLastMessageId] = useState(null);
  const queryClient = useQueryClient();
  const { data: ChatMessages, refetch: ChatMessagesRefecth } =
    useGetUsersChatMessages(id);

  console.log("ChatMessages", ChatMessages?.data?.data);

  const {
    data: batchList,
    isLoading: batchListLoading,
    error: batchListError,
    refetch: batchListRefetch,
  } = useGetBatchList();

  console.log("batchList", batchList);
  // const shouldRenderName = item.questions && item.questions.length > 0;

  const { mutate: shareChatMessage, isLoading: shareChatMessageLoading } =
    useShareChatMessages(id);

  const Typing_Changes = (e) => {
    const data = e.target.value;
    setNewMessage(data);
  };

  useEffect(() => {
    ChatMessagesRefecth(id);
  }, [chatId]);

  // hover animation
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredDownload, setIsHoveredDownload] = useState(false);
  const handleMouseEnterDownload = () => {
    setIsHoveredDownload(true);
  };
  const handleMouseLeaveDownload = () => {
    setIsHoveredDownload(false);
  };

  const [selectedBatch, setSelectedBatch] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  // for input Box Glow on Click

  const [isInputFocused, setIsInputFocused] = useState(false);
  console.log("isInputFocused", isInputFocused);
  const handleInputFocus = () => {
    setIsInputFocused(true);
  };
  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const {
    data: followUpMessages,
    isLoading: followUpMessagesLoading,
    refetch: followUpMessagesRefecth,
    error: followUpMessagesError,
  } = useGetFollowUpMessages(id);

  console.log("followUpMessages", followUpMessages);

  function isTimeInRange() {
    // Set the timezone to UTC+05:30 (Indian Standard Time)
    const timezoneOffset = 5.5 * 60; // Offset in minutes (UTC+05:30)
    const now = new Date();

    // Get current UTC time in minutes
    const currentUTC = now.getUTCHours() * 60 + now.getUTCMinutes();

    // Calculate 9 AM and 6 PM in UTC+05:30
    const nineAM = 9 * 60 + timezoneOffset;
    const sixPM = 18 * 60 + timezoneOffset;

    // Check if current time is between 9 AM and 6 PM
    if (currentUTC <= nineAM && currentUTC < sixPM) {
      return true;
    } else {
      return false;
    }
  }

  let questionsArray = [];
  const [isActiveToPush, setIsActiveToPush] = useState(false);
  const sendNextQuestion = (batchIndex, questionIndex) => {
    const batch = batchList?.data?.data[batchIndex];

    if (batch && questionIndex < batch.questions.length) {
      const question = batch.questions[questionIndex];
      send_Massages(question);
      setCurrentQuestionIndex(questionIndex + 1);
    } else {
    }
  };
  useEffect(() => {
    if (messages?.length > 0) {
      send_Massages(messages[0]);
    }
  }, [messages]);
  const handleSelectBatch = async (index) => {
    if (newChatList?.data?.data?.length === 0) {
      toast.info("Please create a new chat before selecting a batch.");
      return;
    }
    if (chatMessageLoading) {
      alert(
        "Please wait for the current message to finish processing before selecting a new batch."
      );
      return;
    }
    setSelectedBatch(index);
    setCurrentQuestionIndex(0);
    setMessages(batchList?.data?.data[index]?.questions?.map((item) => item));

    return;
    const batch = batchList?.data?.data[index];
    for (let i = 0; i < batch.questions.length; i++) {
      const element = batch.questions[i];

      // Check if it's within chat hours
      if (!isTimeInRange()) {
        toast.info("Chat is only available between 9 AM and 6 PM.");
        return;
      }

      // Skip empty elements
      if (element === "") continue;

      // Update user chats
      queryClient.setQueryData(["user-chats", id], (data) => {
        const oldMessages = data.data.data;
        const newMessages = [
          ...oldMessages,
          {
            message: element,
            role: "user",
            createdAt: new Date(),
          },
        ];
        return {
          data: {
            data: newMessages,
          },
        };
      });

      setNewMessage("");

      try {
        // Send message and await response
        const response = await new Promise((resolve, reject) => {
          createChatMessage(
            { id: id, history: [], question: element },
            {
              onSuccess: (res) => {
                resolve(res); // Resolve promise when message sent successfully
              },
              onError: (err) => {
                reject(err); // Reject promise on error
              },
            }
          );
        });

        // Handle success response
        console.log("Message sent successfully:", response);

        // Additional logic after successful message send
        ChatMessagesRefecth();
        setNewMessage("");
        setIsInputFocused(false);

        // Send next question if there's a selected batch
        if (selectedBatch !== null) {
          sendNextQuestion(selectedBatch, currentQuestionIndex);
        }

        // Optionally, handle recursion for next question in batch
        if (questionsArray.length > 0) {
          handleSelectBatch(0);
        }
      } catch (error) {
        // Handle errors
        console.error("Error sending message:", error);
        toast.error("Error Sending Message");
      }
    }
  };

  const send_Massages = (message = newMessage) => {
    // if (!isTimeInRange()) {
    //   toast.info('Chat is only available between 9 AM and 6 PM.');
    //   return;
    // }
    if (message === "") return;
    if (walletData?.data?.at(0)?.balance === 0) {
      toast.error("Insufficient Balance");
      return;
    }
    console.log("works till here 1");
    queryClient.setQueryData(["user-chats", id], (data) => {
      const oldMessages = data.data.data;
      const newMessages = [
        ...oldMessages,
        {
          message: message,
          role: "user",
          createdAt: new Date(),
        },
      ];
      return {
        data: {
          data: newMessages,
        },
      };
    });

    setNewMessage("");
    console.log("well message", message, newMessage);
    createChatMessage(
      { id: id, history: [], question: message },
      {
        onSuccess: async (res) => {
          setIsActiveToPush(false);
          console.log("sdhfgsydf", res.data.data.botMessage._id, message);
          // questionsArray.pop(message);
          // const removeItem = (itemToRemove) => {
          //   questionsArray = questionsArray.filter(
          //     (question) => question !== itemToRemove
          //   );
          // };
          // removeItem(message);
          setLastMessageId(res?.data?.data?.botMessage?._id.toString());

          if (questionsArray.length > 0) {
            handleSelectBatch(0);
          }

          await ChatMessagesRefecth();
          messages.shift();
          console.log("herezzz 1");

          if (ChatMessages?.data?.data?.length === 2) {
            console.log("herezzz 2");
            await axios
              .get(`api/v1/chat-session/generate-chat-name/${routeId}`, {
                headers: {
                  Authorization: `Bearer ${GetTokenFunc()}`,
                },
              })
              .then((res) => {
                console.log("res herezzz", res);
              });
            newChatListRefetch();
          }
          setMessages([...messages]);
          setNewMessage("");
          setIsInputFocused(false);
          followUpMessagesRefecth();
          setAnimateLastMessage(true);
          // Send next question if there's a selected batch
          // if (selectedBatch !== null) {
          //   console.log('Current Question Index:', currentQuestionIndex); // Debug log
          //   sendNextQuestion(selectedBatch, currentQuestionIndex);
          // }
        },
        onError: (err) => {
          toast.error("Error Sending Message");
        },
      }
    );
  };
  const handleTagClick = (tagText) => {
    if (chatMessageLoading) {
      toast.info("Please wait for the current message to finish processing.");
      return;
    }
    setNewMessage(tagText);
    send_Massages(tagText);
  };
  // speech
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const handleSpeechRecognition = () => {
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening();
    }
  };
  useEffect(() => {
    setNewMessage(transcript);
  }, [transcript]);

  useEffect(() => {
    setIsListening(listening);
  }, [listening]);

  // profile
  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
    refetch: profileDataRefetch,
  } = useGetUserProfile();

  const openSourceLink = async (fileData, page = 0) => {
    try {
      const response = await fetch(fileData);
      const existingPdfBytes = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      const helveticaFont = await pdfDoc.embedFont("Helvetica");

      const textColor = rgb(180 / 255, 194 / 255, 197 / 255);

      const generateSequence = () => {
        const sequence = [];
        const alphabet = "abcdefghijklmnopqrstuvwxyz";
        for (let i = 0; i < alphabet.length; i++) {
          sequence.push(alphabet[i]);
        }
        for (let i = 0; i < alphabet.length; i++) {
          for (let j = 0; j < alphabet.length; j++) {
            sequence.push(alphabet[i] + alphabet[j]);
          }
        }
        return sequence;
      };

      const sequences = generateSequence();
      const pages = pdfDoc.getPages();

      const lettersPerPage = 6;

      pages.forEach((page, pageIndex) => {
        const { width, height } = page.getSize();

        const headerHeight = 50;
        const footerHeight = 40;
        const contentHeight = height - headerHeight - footerHeight;

        const contentTop = height - headerHeight;
        const contentBottom = footerHeight;

        page.drawText(`Licensed to : ${profileData?.data?.data?.fullName}`, {
          x: 20,
          y: height - 30,
          size: 12,
          font: helveticaFont,
          color: textColor,
        });

        const letterSpacing = contentHeight / (lettersPerPage + 1);

        const startIndex = pageIndex * lettersPerPage;

        for (let i = 0; i < lettersPerPage; i++) {
          const sequenceIndex = startIndex + i;
          if (sequenceIndex < sequences.length) {
            const yPosition = contentTop - (i + 1) * letterSpacing;

            if (yPosition > contentBottom) {
              page.drawText(sequences[sequenceIndex], {
                x: width - 40,
                y: yPosition,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
              });
            }
          }
        }

        page.drawText(`Page ${pageIndex + 1} of ${pages.length}`, {
          x: width - 100,
          y: 20,
          size: 10,
          font: helveticaFont,
          color: rgb(0.5, 0.5, 0.5),
        });
      });

      const pdfBytes = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      setIsPdfVisible(true);
      setShowPdf(pdfUrl);
      setPageNumber(page);
    } catch (error) {
      console.error("Error opening PDF:", error);
    }
  };
  // download chat
  const downloadChat = () => {
    var chatData = ChatMessages?.data?.data;
    var chatName = profileData?.data?.data?.fullName;
    if (chatData && chatData.length > 0) {
      var csvContent =
        "Time,Role,Name,Message\n" +
        chatData
          .map((item, i) => {
            const formattedDate = new Date(item.createdAt).toLocaleString();
            return `${item.role === "user" ? "User" : "Bot"},${formattedDate},${
              item.role === "user" ? chatName : "Bot"
            },"${item.message}"`;
          })
          .join("\n");

      const element = document.createElement("a");
      const file = new Blob([csvContent], { type: "text/txt" });
      element.href = URL.createObjectURL(file);
      element.download = "chat.txt";
      document.body.appendChild(element);
      element.click();
    } else {
      toast.error("Chat is empty. Cannot download.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // Comment Section
  const [ChatModal, setChatModal] = useState(false);
  const [chatInput, setChatInput] = useState(true);
  const handleChatModalopen = () => {
    setChatModal(true);
  };

  // edit pdf file name
  const [editing, setEditing] = useState(false);
  const [newBatchName, setNewBatchName] = useState(
    chatData?.batchName || chatData?.fileData?.at(0)?.fileName
  );

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = () => {
    // onSave(newBatchName);
    setEditing(false);
  };
  const messageBoxRef = useRef();

  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  }, [ChatMessages]);

  const handleInputChange = (e) => {
    setNewBatchName(e.target.value);
  };

  const [loading, setLoading] = useState(false);
  const handleShareClick = async () => {
    setLoading(true);
    const confirm = window.confirm("Are you sure you want to share this chat?");
    if (!confirm) {
      setLoading(false);
      return;
    }
    shareChatMessage(id, {
      onSuccess: (res) => {
        toast.success("Chat Shared Successfully");
        setLoading(false);
      },
      onError: (err) => {
        toast.error("Error Sharing Chat");
        setLoading(false);
      },
    });
  };
  // download text file

  // const isMessageListLessThanThree = ChatMessages?.data?.data?.length < 1;

  const inputRef = useRef(null);

  // Effect to clear the input field when triggered
  useEffect(() => {
    if (clearInput === true || clearInput === false) {
      setNewMessage("");
    }
  }, [clearInput]);
  const {
    data: fileUploadList,
    isLoading: fileUploadListLoading,
    isError: fileUploadListError,
    refetch: refetchFileUploadList,
  } = useGetUsersPdfs();

  const {
    data: newChatList,
    isLoading: newChatListLoading,
    error: newChatListError,
  } = useGetNewChatList();

  console.log("newChatList", newChatList?.data?.data?.length);

  const scrollBoxRef = useRef(null);
  const scrollForward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: 200, behavior: "smooth" }); // Adjust the scroll amount as needed
    }
  };

  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    if (animateLastMessage) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  }, [animateLastMessage]);

  useEffect(() => {
    const handleScroll = () => {
      if (messageBoxRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = messageBoxRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          // Added a small buffer
          setShowScrollButton(false);
        } else {
          setShowScrollButton(true);
        }
      }
    };

    if (messageBoxRef.current) {
      messageBoxRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (messageBoxRef.current) {
        messageBoxRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollToBottom = () => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTo({
        top: messageBoxRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue: newChatSetValue,
    formState: { errors },
  } = useForm({});
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const handleOpenLinkModal = () => setOpenLinkModal(true);
  const handleCloseLinkModal = () => setOpenLinkModal(false);
  const [expandedSources, setExpandedSources] = useState({});

  const handleSourceLinkClick = (messageId) => {
    setExpandedSources((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
  };

  // const openSourceLink = (fileData, page) => {
  //   setIsPdfVisible(true);
  //   setShowPdf(fileData);
  //   setPageNumber(0);
  // };
  const {
    mutate: newChatMutate,
    isLoading: newChatLoading,
    isError: newChatError,
  } = useCreateNewChat();
  const newChatNameSubmit = handleSubmit((data) => {
    const chatData = {
      ...data,
      name: data.name || "New Chat",
    };
    newChatMutate(chatData, {
      onSuccess: async (response) => {
        toast.success("Chat Created Successfully");
        // handleCloseSingle();
        await newChatListRefetch();
        reset();
        // setIsPdfVisible(false);
        navigate("/mainchat/" + response?.data?.data?._id);
      },
      onError: (error) => {},
    });
  });
  // copy messages to clipboard
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const copyToClipboard = (text, messageId) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Message copied to clipboard!");
      setCopiedMessageId(messageId);
      setTimeout(() => setCopiedMessageId(null), 2000); // Reset after 2 seconds
    });
  };

  return (
    <>
      <Box
        sx={{
          flex: gridSize ? "1 1 83%" : " 1 1 60%",
          transition: "flex-basis 0.5s linear",
          mt: 0,
        }}
      >
        <Paper
          elevation={2}
          sx={{
            boxShadow: "0px 10px 20px 0px rgba(147, 147, 147, 0.25)",
            borderRadius: "18px",
            mt: 0,
          }}
        >
          <Box sx={{ ...Styles.MessageViewStyle }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: "20px",
                py: "0px",
                borderBottom: "2px solid",
                // borderBottomColor: 'primary.main',
                borderBottomColor: "#68858C",
                width: "90%",
                mx: "auto",
                p: { xl: 2, lg: 1, md: 1 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    maxWidth: "300px",
                    overflowX: "auto",
                    // textOverflow: 'ellipsis',
                  }}
                  ref={scrollBoxRef}
                >
                  {batchList?.data?.data?.map((item, index) => {
                    const isSelected = selectedBatch === index;
                    if (!item.questions || item.questions.length === 0) {
                      return null;
                    }
                    return (
                      <Tooltip title={item?.name} placement="top" arrow>
                        <Box
                          key={index}
                          sx={{
                            background: "rgba(247, 249, 251, 1)",
                            height: "auto",
                            minWidth: "90px",
                            maxWidth: "90px",
                            p: 0.8,
                            borderRadius: "5px",
                            gap: "10px",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                            alignItems: "center",
                            transition: "all 0.3s ease",
                            opacity: chatMessageLoading ? 0.5 : 1,
                            "&:hover": {
                              background: "#CEE8EE",
                            },
                          }}
                          // onClick={() => handleSelectBatch(index)}
                          onClick={
                            chatMessageLoading
                              ? undefined
                              : () => handleSelectBatch(index)
                          }
                        >
                          <Box
                            component={"img"}
                            src={isSelected ? batchImg : batchGreyImg}
                            sx={{ width: "auto", height: "auto" }}
                          />
                          <Typography
                            sx={{
                              whiteSpace: "normal",
                              overflow: "auto",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              fontFamily: "Manrope",
                              fontSize: "10px",
                              fontWeight: 600,
                              textAlign: "center",
                              color: isSelected
                                ? "#68858C"
                                : "rgba(133, 139, 160, 1)",
                            }}
                          >
                            {item?.name}
                          </Typography>
                        </Box>
                      </Tooltip>
                    );
                  })}
                </Box>
                <Box onClick={scrollForward} sx={{ cursor: "pointer" }}>
                  <ChevronRightIcon
                    sx={{
                      "&:hover": { color: "primary.main" },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  onClick={downloadChat}
                  sx={Styles.AIText}
                  onMouseEnter={handleMouseEnterDownload}
                  onMouseLeave={handleMouseLeaveDownload}
                >
                  Download
                  <DownloadIcon
                    sx={{
                      color: isHoveredDownload ? "primary.main" : "#000",
                      fontSize: "14px",
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  />
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "45%",
                    alignItems: "center",
                  }}
                >
                  {loading ? (
                    <>
                      <Ring
                        size={30}
                        lineWeight={12}
                        speed={2}
                        color="#605BFF"
                      />
                    </>
                  ) : (
                    <>
                      {/* <Box
                        component={'img'}
                        src={isShareIconHovered ? share : shareInactive}
                        alt="img"
                        sx={{
                          ...Styles.IconStyle,
                          width: '16px',
                          filter: isShareIconHovered
                            ? 'invert(43%) sepia(20%) saturate(251%) hue-rotate(163deg) brightness(91%) contrast(92%)'
                            : 'none',
                        }}
                        // onClick={handleShareClick}
                        onClick={() => navigate(`/viewsharechat/${id}`)}
                        onMouseEnter={handleShareIconHover}
                        onMouseLeave={handleMouseLeaveHoevr}
                      /> */}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            <div style={{ position: "relative" }}>
              <Box ref={messageBoxRef} sx={mainChatBox}>
                {ChatMessages?.data?.data?.map((item, index) => {
                  let pageNumber;
                  let message = "";
                  // const match = item?.message?.match(/Page Number: (\d+)/);

                  // if (match && match[1]) {
                  //   // Extracted page number
                  //   pageNumber = parseInt(match[1], 10);

                  //   // Extract the message part
                  //   const messageMatch = item?.message?.match(/Output: Data(.+)/);
                  //   message =
                  //     messageMatch && messageMatch[1] ? messageMatch[1] : '';
                  // } else {
                  // }
                  message = item?.message;
                  // console.log('well message', message);
                  // const isLastMessage =
                  //   index === ChatMessages?.data?.data?.length - 1;
                  const isLastMessage =
                    index === ChatMessages.data.data.length - 1;
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems:
                          item.role === "user" ? "flex-end" : "flex-start",
                        mb: "20px",
                        mt: "20px",
                        width: "90%",
                        mx: "auto",
                      }}
                    >
                      <div
                        style={{
                          textAlign: item.role === "user" ? "right" : "left",
                          marginTop: "25px",
                          position: "relative",
                        }}
                      >
                        {item.role === "user" ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              // width: '100%',
                              right: "0px",
                              float: "right",
                              marginTop: "-20px",
                              // marginLeft: '40px',
                              ml: "auto",
                              // mr: 5,
                            }}
                          >
                            <Typography sx={Styles.timeStaps}>
                              {new Date(item.createdAt).getHours()}:{" "}
                              {new Date(item.createdAt).getMinutes()}
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              right: "0px",
                              marginTop: "-20px",
                              // marginLeft: '40px',
                              // ml: 4,
                              mr: 0,
                            }}
                          >
                            <Typography sx={Styles.timeStaps}>
                              {new Date(item.createdAt).getHours()}:{" "}
                              {new Date(item.createdAt).getMinutes()}
                            </Typography>
                          </Box>
                        )}
                        <Box
                          sx={{
                            ...Styles.messagesText,
                            backgroundColor:
                              item.role === "user"
                                ? "secondary.main"
                                : "#CEE8EE",
                            color: item.role === "user" ? "#fff" : "#000",
                            position: "relative",
                            py: item.role === "user" ? 0 : 1,
                            hyphens: item.role === "user" ? "auto" : "none",
                            minWidth: item.role === "user" ? "auto" : "130px",
                            maxWidth: item.role === "user" ? "80%" : "60%",
                            // lineHeight: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Box>
                              {isLastMessage &&
                              animateLastMessage &&
                              lastMessageId === item._id &&
                              item.role !== "user" ? (
                                <AnimatedText
                                  text={message}
                                  speed={50}
                                  lineByLine={false}
                                  setLastMessageId={setLastMessageId}
                                />
                              ) : (
                                <Markdown
                                  components={{
                                    pre: "p",
                                    code: "p",
                                  }}
                                >
                                  {message}
                                </Markdown>
                              )}

                              {item.role !== "user" &&
                              item.fileData &&
                              item.fileData.length > 0 ? (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "25px",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      mx: "auto",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap",
                                        gap: "10px",
                                        width: "100%",
                                        // maxWidth: '600px',
                                        overflowX: "auto",
                                      }}
                                    >
                                      {/* sx={Styles.sourceLink} */}
                                      {/* comment out for now */}
                                      {item.fileData.map((url, index) =>
                                        typeof url === "string" ? (
                                          <Box
                                            key={index}
                                            sx={Styles.sourceLink}
                                            onClick={() => openSourceLink(url)}
                                          >
                                            Source Link {index + 1}
                                          </Box>
                                        ) : (
                                          <Tooltip
                                            title={url?.pageContent}
                                            key={index}
                                            placement="right-end"
                                            componentsProps={{
                                              tooltip: {
                                                sx: {
                                                  maxHeight: "200px",
                                                  backgroundColor: "#FFF",
                                                  color: "#000",
                                                  fontSize: "14px",
                                                  overflowY: "auto",
                                                  padding: "8px",
                                                  borderRadius: "4px",
                                                  boxShadow:
                                                    "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                                },
                                              },
                                              popper: {
                                                sx: {
                                                  maxWidth: "400px", // Set the maximum width of the tooltip if needed
                                                },
                                              },
                                            }}
                                          >
                                            <Box
                                              key={index}
                                              sx={Styles.sourceLink}
                                              onClick={() =>
                                                openSourceLink(
                                                  url?.metadata?.source ??
                                                    url?.metadata?.fileName
                                                      ?.fileUrl,
                                                  url?.metadata?.pageNumber ??
                                                    url?.metadata?.page - 1
                                                )
                                              }
                                            >
                                              Source Link {index + 1}
                                            </Box>
                                          </Tooltip>
                                        )
                                      )}
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      alignItems: "center",
                                      gap: "10px",
                                      mt: 2,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display:
                                          item.role === "bot" ? "flex" : "none",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Tooltip
                                        title="comment"
                                        placement="left"
                                        arrow
                                      >
                                        <InsertCommentOutlinedIcon
                                          onClick={() =>
                                            handleOpenComments(item)
                                          }
                                          sx={{
                                            cursor: "pointer",
                                            fontSize: "20px",
                                          }}
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Tooltip
                                      title="copy"
                                      placement="right"
                                      arrow
                                    >
                                      {item.role !== "user" && (
                                        <Box
                                          onClick={() =>
                                            copyToClipboard(
                                              item.message,
                                              item._id
                                            )
                                          }
                                          sx={{
                                            minWidth: "auto",
                                            padding: "2px",
                                            width: "10px",
                                            cursor: "pointer",
                                            mr: 3,
                                          }}
                                        >
                                          <ContentCopyIcon
                                            sx={{
                                              fontSize: "16px",
                                              color:
                                                copiedMessageId === item._id
                                                  ? "primary.main"
                                                  : "inherit",
                                            }}
                                          />
                                        </Box>
                                      )}
                                    </Tooltip>
                                  </Box>
                                </>
                              ) : null}
                            </Box>
                            <Box
                              sx={{ display: "flex", alignSelf: "flex-end" }}
                            >
                              {pageNumber ? (
                                <Button
                                  sx={Styles.pdfCountPageNumber}
                                  onClick={() => {
                                    setPageNumber(10);
                                  }}
                                >
                                  {pageNumber}
                                </Button>
                              ) : null}
                            </Box>
                          </Box>
                        </Box>
                      </div>
                    </Box>
                  );
                })}
                {chatMessageLoading ? (
                  <Box sx={{ ml: 4, mb: 4 }}>
                    {/* <video
                    autoPlay
                    loop
                    src={circleloader}
                    alt=""
                    style={{ height: 'auto', width: '45px' }}
                  /> */}
                    <DotPulse
                      size={20}
                      lineWeight={2}
                      speed={2}
                      color="rgba(9, 26, 50, 1)"
                    />
                  </Box>
                ) : null}
                {/* {showScrollButton && (
                  <Button
                    onClick={scrollToBottom}
                    sx={{
                      position: 'absolute',
                      bottom: '10px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      zIndex: 1000, // adjust z-index as needed
                      backgroundColor: '#fff',
                      borderRadius: '100%',
                    }}
                  >
                    <span
                      style={{
                        content: '\u00A0',
                      }}
                    >
                      &#8595;
                    </span>
                  </Button>
                )} */}
              </Box>
            </div>
            <Box sx={Styles.scrollTagBox}>
              {followUpMessagesLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <DotPulse
                    size={20}
                    lineWeight={2}
                    speed={2}
                    color="rgba(9, 26, 50, 1)"
                  />
                </Box>
              ) : (
                <>
                  {followUpMessages?.data?.questions?.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          ...Styles.tagText,
                          // cursor: chatMessageLoading ? 'not-allowed' : 'pointer',
                          opacity: chatMessageLoading ? 0.5 : 1,
                          transition: "all 0.3s ease",
                          "&:hover": {
                            backgroundColor: chatMessageLoading
                              ? "inherit"
                              : "rgba(206, 232, 238, 1)",
                            color: chatMessageLoading ? "inherit" : "#000",
                          },
                        }}
                        onClick={
                          chatMessageLoading
                            ? undefined
                            : () => handleTagClick(item)
                        }
                      >
                        + &nbsp; &nbsp; {item}
                      </Box>
                    );
                  })}
                </>
              )}
            </Box>
            {newChatList?.data?.data?.length > 0 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "93%",
                    mx: "auto",
                    gap: "10px",
                    background: "transparent",
                    mb: 2,
                    mt: 0.5,
                  }}
                >
                  <Box
                    onClick={handleSpeechRecognition}
                    sx={{
                      ...Styles.micStyle,
                      transform: isListening ? "scale(1.3)" : "scale(1)",
                      borderRadius: isListening ? "50px" : "10px",
                      boxShadow: isListening
                        ? "0px 0px 0px 2.5px rgba(104, 133, 140, 1)"
                        : "none",
                    }}
                    // onMouseOver={(e) => {
                    //   e.currentTarget.style.transform = 'scale(1.3)';
                    // }}
                    // onMouseOut={(e) => {
                    //   e.currentTarget.style.transform = 'scale(1.2)';
                    // }}
                  >
                    <MicIcon
                      sx={{
                        color: "white",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      ...Styles.messageInputStyle,
                      "&:hover": {
                        border: "2px solid",
                        borderColor:
                          // !isInputFocused || chatMessageLoading
                          //   ? '#E0E0E0'
                          //   : 'primary.main',
                          isInputFocused && chatMessageLoading
                            ? "primary.main"
                            : "#091A32",
                      },
                      opacity: chatMessageLoading ? 0.5 : 1,
                      borderColor:
                        !isInputFocused || chatMessageLoading
                          ? "primary.main"
                          : "#091A32",
                    }}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Input
                        value={newMessage}
                        name="Ask any Question"
                        onChange={(e) => {
                          // if (Number(routeId) === 1 || isCommentList) {
                          //   toast.info('please add one comment first');
                          // } else {
                          //   Typing_Changes(e);
                          // }
                          if (
                            // Number(routeId) === 0 ||
                            // isCommentList ||
                            e.target.value &&
                            e.target.value.length > 1000
                          ) {
                            if (
                              e.target.value &&
                              e.target.value.length > 1000
                            ) {
                              toast.info(
                                "Please limit your input to 1000 characters."
                              );
                            } else {
                              toast.info("Please add one comment first.");
                            }
                          } else {
                            Typing_Changes(e);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.target.value === "" || !e.target.value) return;
                          if (e.key === "Enter") {
                            send_Massages();
                          }
                        }}
                        fullWidth
                        placeholder="Ask any Question"
                        sx={{ fontSize: "16px", ml: 0.8 }}
                        disableUnderline
                        // disabled={
                        //   Number(routeId) === 1 ? true : false || isCommentList
                        // }
                        disabled={chatMessageLoading}
                      />
                    </Box>

                    <button
                      disabled={chatMessageLoading || newMessage === ""}
                      style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={sendicon}
                        alt=""
                        onClick={() => {
                          send_Massages();
                        }}
                        // style={Styles.SendIcon}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.1)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                        }}
                        // sx={{ height: "auto", width: "45px" }}
                      />
                    </button>
                  </Box>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Tooltip title="Please create first chat" placement="top">
                  <Box
                    sx={{
                      ...Styles.newChat,
                      width: "auto",
                      height: "auto",
                      p: 1,
                    }}
                    onClick={() => newChatNameSubmit()}
                  >
                    + Please create first chat
                  </Box>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
      {openComments && (
        <Modal
          open={handleOpenComments}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={handleCloseComments}
              sx={{
                position: "absolute",
                right: 8,
                top: 0,
                color: "#555",
              }}
            >
              <CloseIcon />
            </IconButton>
            <TextField
              id="message"
              name="message"
              size="small"
              multiline
              rows={3}
              variant="outlined"
              sx={textfeilddesign}
              // value={formData.message}
              // onChange={handleInputChange}
              required
              placeholder="Send your feedback here"
            />
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                send
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default MessageBox;

const mainChatBox = {
  height: "100%",
  minHeight: { xl: "64vh", lg: "64vh", md: "64vh" },
  maxHeight: "64vh",
  overflowY: "auto",
  overflowX: "hidden",
  margin: "0px",
  marginTop: { xl: "20px", lg: "5px", md: "0px" },
  backgroundImage: `url(${BGImage})`,
  backgroundSize: "cover",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: 400, lg: 400, md: 400, sm: 300, xs: 300 },
  bgcolor: "background.paper",
  background: "linear-gradient(135deg, #ece9e6 0%, #ffffff 100%)",
  borderRadius: "12px",
  boxShadow: 24,
  p: { xl: 3, lg: 3, md: 3, sm: 1.5, xs: 1.5 },
  outline: "none", // Remove the default border
};

const textfeilddesign = {
  mt: 2,
  width: "100%",
  "& .MuiInput-underline:before": {
    borderBottomColor: "secondary.main", // Normal state
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomColor: "primary.main", // Hover state
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "secondary.main", // Focused state
  },
};
