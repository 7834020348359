import React from 'react';
import {
  Paper,
  Box,
  Typography,
  Button,
  Tooltip,
  Skeleton,
} from '@mui/material';
import * as Styles from '../../Common/Styles.js';
import greyforwardicon from '../../Assets/mainPage/greyforwardicon.svg';
import blogsone from '../../Assets/mainPage/blogsone.svg';
import menlogo from '../../Assets/mainPage/menlogo.svg';
import saveposticon from '../../Assets/mainPage/saveposticon.svg';
import { newsArticles } from './data.js';
import blackDot from '../../Assets/mainPage/blackDot.svg';
import parasmalllogo from '../../Assets/mainPage/parasmalllogo.svg';
import { useGetBlogs } from '../../Hooks/blogHooks';
import parasorc from '../../Assets/Homeimg/parasorc.png';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
const Articles = () => {
  const navigate = useNavigate();
  const {
    data: blogsData,
    isLoading: blogsLoading,
    error: blogsError,
    refetch: blogsDataRefetch,
  } = useGetBlogs();

  return (
    <div>
      <Box sx={mainArticleSetUp}>
        <Box
          sx={{
            width: '80%',
            mx: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Fade top duration={1000}>
            <Box
              component={'span'}
              sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
            >
              <Box
                component={'img'}
                src={blackDot}
                sx={{ height: 'auto', width: 'auto' }}
              />
              <Typography sx={Styles.boxMainText}>Popular Articles </Typography>
            </Box>
          </Fade>{' '}
          <Box
            onClick={() => navigate('/viewallarticles')}
            sx={{
              ...Styles.getintouchbtn,
              background: 'rgba(247, 249, 251, 1)',
              color: 'rgba(62, 50, 50, 0.75)',
              borderRadius: '10px',
              boxShadow: 1,
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                // transform: 'scale(1.02)',
                boxShadow: 3,
              },
            }}
          >
            Show all &nbsp; &nbsp;
            <Box
              component={'img'}
              src={greyforwardicon}
              alt=""
              sx={{ height: 'auto', width: 'auto' }}
            />
          </Box>
        </Box>
        <Box sx={setUpArticles}>
          {blogsData?.data
            ?.filter((item) => item.creator === 'Nancy Sharma')
            .slice(0, 4)
            .map((item, index) => (
              <Tooltip title="read full Article" placement="top" arrow>
                {blogsLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={610}
                    height={120}
                    sx={{ mt: 1 }}
                  />
                ) : (
                  <Box
                    key={index}
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      navigate(`/viewblog`, {
                        state: {
                          title: item?.title,
                          content: item?.encoded,
                          creator: item?.creator,
                          pubDate: item?.pubDate,
                        },
                      });
                    }}
                    sx={{
                      mt: 2,
                      width: {
                        xl: '49%',
                        lg: '49%',
                        md: '49%',
                        sm: '90%',
                        xs: '90%',
                      },
                      mb: 2,
                      cursor: 'pointer',
                    }}
                  >
                    <Zoom duration={1000}>
                      <Box sx={blogsMainBox}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            p: 1.5,
                            gap: '10px',
                            flexDirection: 'column',
                            background: 'rgba(247, 249, 251, 1)',
                            borderRadius: '10px',
                            width: 'auto',
                            minWidth: '120px',
                          }}
                        >
                          <Box
                            component={'img'}
                            src={parasmalllogo}
                            alt=""
                            sx={{
                              height: 'auto',
                              maxWidth: '50px',
                            }}
                          />
                          <Typography sx={Styles.parasmallText}>
                            para &nbsp;
                            <span style={{ color: 'rgba(141, 164, 169, 1)' }}>
                              sors
                            </span>
                          </Typography>
                          <Typography sx={Styles.articleText}>
                            Article {index + 1}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography sx={Styles.blogmainText}>
                            {item?.title}
                          </Typography>
                          <Typography
                            sx={{
                              ...Styles.subheadingtabletext,
                              textAlign: 'left',
                              width: '100%',
                              whiteSpace: 'normal',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                              mt: 1,
                            }}
                          >
                            {item?.contentSnippet}
                            {/* {item?.contentSnippet} */}
                          </Typography>
                          <Box sx={savePost}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    ...Styles.tableBodySubText,
                                    textAlign: 'left',
                                  }}
                                >
                                  {item?.creator}
                                </Typography>
                                <Typography sx={Styles.subheadingtabletext}>
                                  {new Date(item?.pubDate).toLocaleDateString(
                                    'en-US',
                                    {
                                      weekday: 'short',
                                      day: '2-digit',
                                      month: 'short',
                                      year: 'numeric',
                                    }
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                            {/* <Box
                        component={'img'}
                        src={saveposticon}
                        sx={{
                          height: 'auto',
                          width: 'auto',
                          cursor: 'pointer',
                        }}
                        alt=""
                      /> */}
                          </Box>
                        </Box>
                      </Box>
                    </Zoom>
                  </Box>
                )}
              </Tooltip>
            ))}
        </Box>
      </Box>
    </div>
  );
};

export default Articles;

const blogsMainBox = {
  borderRadius: '10px',
  p: 1,
  height: '100%',
  minHeight: '100%',
  width: { xl: '100%', lg: '100%', md: '100%', sm: '95%', xs: '95%' }, // Adjusted to be responsive
  minWidth: { xl: '100%', lg: '100%', md: '100%', sm: '95%', xs: '95%' }, // Adjusted to be responsive
  background: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 1px 2px 1px rgba(147, 147, 147, 0.25)',
  display: 'flex',
  flexDirection: {
    xl: 'row',
    lg: 'row',
    md: 'row',
    sm: 'column',
    xs: 'column',
  },
  gap: '10px',
};
const mainArticleSetUp = {
  width: '100%',
  background: '#fff',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  mt: 0,
  p: 2,
};
const savePost = {
  background: 'rgba(247, 249, 251, 1)',
  p: 0.8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: { xl: '98%', lg: '98%', md: '98%', sm: '97%', xs: '96%' },
  mx: 'auto',
  mt: 3,
  borderRadius: '10px',
};

const setUpArticles = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '80%',
  flexDirection: {
    xl: 'row',
    lg: 'row',
    md: 'row',
    sm: 'column',
    xs: 'column',
  },
  mx: 'auto',
};
